import mock from '@src/@fake-db/mock'
import { paginateArray } from '@src/@fake-db/utils'

export const attachmentData = {
  total: 100,
  size: 5,
  page: 1,
  items: [
    {
      id: 'f0477dec-b7de-418b-bf55-c98ea886e1ce',
      name: 'UltricesPosuereCubilia.pdf',
      createdOn: '2021-02-13T03:01:12Z',
    },
    { id: 'f014ead6-b6d0-4d2c-ae9f-b2a99c88291e', name: 'LuctusEtUltrices.ppt', createdOn: '2021-11-13T04:19:21Z' },
    { id: 'c5fe6c1a-70c4-4fdb-8d7c-6feb74c220e8', name: 'SagittisDuiVel.tiff', createdOn: '2021-06-05T02:26:42Z' },
    { id: 'd06e3544-d15b-49ed-9ef0-e986656b000c', name: 'EgestasMetus.mp3', createdOn: '2021-08-06T11:05:24Z' },
    { id: 'ca1b1bac-ca2b-44fb-9d02-b99ce1e71d5b', name: 'TristiqueInTempus.avi', createdOn: '2021-08-25T13:26:56Z' },
    { id: '5b59b9d8-978f-4269-a334-7aed3a2913f3', name: 'ProinEuMi.ppt', createdOn: '2021-11-12T14:19:07Z' },
    { id: 'd530042e-ba53-4464-9bec-d1aff8c093af', name: 'Nunc.avi', createdOn: '2022-01-25T13:53:31Z' },
    { id: '21c7bc3a-cdf6-4ac7-b01f-4d0d336a99c6', name: 'Non.xls', createdOn: '2021-09-11T21:23:09Z' },
    { id: 'f0c9d762-1533-4c54-b7d2-872df386d80d', name: 'ConsequatNullaNisl.mp3', createdOn: '2021-05-09T14:32:32Z' },
    { id: 'f6c22923-f3e8-418f-aa9f-668b55854d4d', name: 'LacusPurusAliquet.pdf', createdOn: '2022-02-02T05:47:28Z' },
  ],
}

mock.onGet(new RegExp('/app/attachment/invoice/*')).reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = attachmentData.items.sort((a, b) => {
    if (!sortColumn) {
      return 1
    }
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter(acitvity => {
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    return acitvity.name?.toLowerCase().includes(queryLowered)
  })
  /* eslint-enable  */

  return [
    200,
    {
      success: true,
      data: {
        pageSize: perPage,
        page,
        total: filteredData.length,
        items: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page),
      },
    },
  ]
})
