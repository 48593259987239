// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from '@api'
import { uuidv4 } from '../../../utility/Utils'

export const getData = createAsyncThunk('payment/getData', async params => {
  const response = await api.get('/app/payments', params)
  return {
    params,
    data: response.data.items,
    allData: response.data.allData,
    totalPages: response.data.total,
  }
})

export const getDetail = createAsyncThunk('payment/detail', async id => {
  const response = await api.get(`/app/payments/${id}`)
  return response.data.data
})

export const deletePayment = createAsyncThunk('payment/deletePayment', async (id, { dispatch, getState }) => {
  await api.delete(`/app/payments/${id}`)
  await dispatch(getData(getState().payment.params))
  return id
})

export const savePayment = createAsyncThunk('payment/save', async ({}, { getState }) => {
  const payment = getState().payments.selected

  await api.put(`/app/payments/${payment.id}`, payment)
  return payment
})

export const paymentslice = createSlice({
  name: 'payment',
  initialState: {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selected: null,
  },
  reducers: {
    deleteItem: (state, action) => {
      if (state.selected && Array.isArray(state.selected.items)) {
        state.selected.items.splice(action.payload, 1)
      }
    },
    addItem: state => {
      if (state.selected && Array.isArray(state.selected.items)) {
        state.selected.items.push({
          id: uuidv4(),
          discount: {
            type: '%',
            percentage: 0,
            amount: 0,
          },
        })
      }
    },
    updateItem: (state, action) => {
      if (state.selected && Array.isArray(state.selected.items)) {
        for (const [key, value] of Object.entries(action.payload.props)) {
          state.selected.items[action.payload.i][key] = value
        }
        const item = state.selected.items[action.payload.i]
        state.selected.items[action.payload.i].total = (item.qty || 0) * (item.rate || 0)
        const discount = state.selected.items[action.payload.i].discount

        if (discount && discount.amount > 0) {
          state.selected.items[action.payload.i].total -= discount.amount
          state.selected.discount = {
            amount: 0,
            ...state.selected.discount,
          }
          state.selected.discount.amount -= discount.amount
        }
      }
    },
    updateField: (state, action) => {
      if (state.selected) {
        for (const [key, value] of Object.entries(action.payload.props)) {
          state.selected[key] = value
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.params = action.payload.params
      })
      .addCase(getDetail.fulfilled, (state, action) => {
        state.selected = action.payload
      })
      .addCase(savePayment.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  },
})
export const { updateItem, addItem, deleteItem, updateField } = paymentslice.actions

export default paymentslice.reducer
