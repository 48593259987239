// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import config from './configuration'
import session from './session'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import clients from '@modules/client/store'
import invoices from '@modules/invoice/store'
import estimates from '@modules/estimate/store'
import payments from '@modules/payment/store'
import expenses from '@modules/expense/store'
import reports from '@modules/report/store'
import dashbaords from '@modules/dashboard/store'
import settings from '@modules/setting/store'

const rootReducer = {
  config,
  session,
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  clients,
  invoices,
  estimates,
  payments,
  expenses,
  reports,
  dashbaords,
  settings,
}

export default rootReducer
