export default {
  avatar: require('@src/assets/images/avatars/avatar-blank.png').default,

  lastName: 'Thla',
  firstName: 'Translaction',
  taxCode: 'K004-90170307',

  industryId: '7096723a-7f0d-47ef-9b12-5f321a91eb37',

  name: 'Thla Translation',
  emails: ['info@inkmer.com'],
  primaryContactEmail: 'info@inkmer.com',
  primaryContactNumber: '+1 (123) 456 7891',
  phones: [
    {
      type: 'mobile',
      formatted: '+1 (123) 456 7891',
      number: '+1 (123) 456 7891',
    },
    {
      type: 'landline',
      number: '+44 (876) 543 2198',
      formatted: '+44 (876) 543 2198',
    },
  ],
  address: {
    fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
    addressLine1: 'Office 149, 450 South Brand Brooklyn',
    addressLine2: '',
    city: 'San Diego County',
    provinceState: 'Phnom Penh',
    zipPostCode: '32222',
    countryCode: 'KH',
  },
}
