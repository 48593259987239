import mock from '../mock'
import notifications from './notifications.json'
import settingGeneral from '@modules/setting/@fake-db/general'
import company from '@modules/setting/@fake-db/company'

const data = {
  auth: { grantedPolicies: {} },
  currentUser: {
    isAuthenticated: false,
    id: null,
    tenantId: null,
    userName: null,
    firstName: null,
    lastName: null,
    email: null,
    emailVerified: false,
    roles: [],
  },
  setting: {
    localization: { defaultLanguage: 'en' },
    timing: { timeZone: 'UTC' },
    identity: {
      password: {
        requiredLength: 6,
        requiredUniqueChars: 1,
        requireNonAlphanumeric: true,
        requireLowercase: true,
        requireUppercase: true,
        requireDigit: true,
      },
      lockout: { allowedForNewUsers: true, lockoutDuration: 300, maxFailedAccessAttempts: 5 },
      signIn: {
        requireConfirmedEmail: false,
        enablePhoneNumberConfirmation: true,
        requireConfirmedPhoneNumber: false,
      },
      user: { isUserNameUpdateEnabled: true, isEmailUpdateEnabled: true },
      organizationUnit: { maxUserMembershipCount: 2147483647 },
    },
    account: { isSelfRegistrationEnabled: true, enableLocalLogin: true },
  },
  features: { settingManagement: { enable: true, allowTenantsToChangeEmailSettings: false } },
  languages: [
    {
      countryCode: 'en',
      flagCode: 'us',
      name: 'English',
    },
    {
      countryCode: 'kh',
      flagCode: 'kh',
      name: 'Khmer',
    },
    {
      countryCode: 'fr',
      flagCode: 'fr',
      name: 'French',
    },
    {
      countryCode: 'de',
      flagCode: 'de',
      name: 'German',
    },
    {
      countryCode: 'pt',
      flagCode: 'pt',
      name: 'Portuguese',
    },
  ],
  notifications,
  invoice: {
    clientNote: settingGeneral.clientNote,
    unitTypes: settingGeneral.unitTypes,
    maxAttachments: 5,
  },
  company,
  taxes: settingGeneral.taxes,
  defaults: {
    languageCode: settingGeneral.language,
    countryCode: settingGeneral.countryCode,
    currencyCode: settingGeneral.currencyCode,
    timezoneCode: settingGeneral.timezoneCode,
    dateFormat: settingGeneral.dateFormat,
    timeFormat: settingGeneral.timeFormat,
    dateTimeFormat: `${settingGeneral.dateFormat} ${settingGeneral.timeFormat}`,
  },
  general: settingGeneral,
}

mock.onGet('/app/configuration/global').reply(() => [200, data])
