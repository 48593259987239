export const items = [
  {
    id: '8fdf10cf-4dfa-4d61-87d2-478ee5c2bdca',
    name: ' ​សៀវភៅស្នាក់នៅ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '9e395e46-fead-46fd-82e7-91af36d6414e',
    name: ' កញ្ចប់រដូវក្តៅសម្រាប់ប្រជាជនកម្ពុជា ២០២០ (ឯកសារបកបន្ថែម Additional translation)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f8df5661-264d-4559-b9ac-a09ffd77d35f',
    name: ' ការស្ដាប់ចម្លើយរបស់ជនសង្ស៍យ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'aae24904-9b40-4a40-ab3f-7e1ff061f792',
    name: ' កាស',
    rate: 2.0,
    unitTypeId: '7d5b7e00-5443-4b24-9ae8-d4c8092be1bc',
  },
  {
    id: '0378dafa-cf72-43b0-b47f-4f358ffab612',
    name: ' បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​ ​២២ ខែ តុលា ឆ្នាំ២០២១)​​ ',
    rate: 360.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0dfbb1a0-8494-414a-90ca-06f9b8b01699',
    name: ' បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​05 ខែ ធ្នូ ឆ្នាំ២០១៩)​​ ពេលព្រឹក',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '4dbda718-65db-4a51-88b2-501f9f0bf2c0',
    name: ' បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​១៣ ខែកញ្ញា ឆ្នាំ២០១៩)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'a831d4d5-8427-4d27-a6d8-219f3ffe8214',
    name: ' បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​១៦ ខែតុលា ឆ្នាំ២០១៩)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '306e1ad5-829a-4f07-8f5d-69b309604b8d',
    name: ' បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​22 ខែកញ្ញា ឆ្នាំ២០១៩)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '18bdf67f-4194-4cbe-b7fb-be908223f93b',
    name: ' បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​26 ខែ វិច្ឆកា ឆ្នាំ២០១៩)​​ ពេលថ្ងៃ',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'ec63cc52-0cbd-4d58-944a-61b4ce2bdbd2',
    name: ' បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​26 ខែ​​ វិច្ឆកា ឆ្នាំ២០១៩) ពេលព្រឹក',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '0ee93a50-f8a2-451c-8e64-048919f69779',
    name: ' បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ​១0 ខែតុលា ឆ្នាំ២០១៩(Bi-monthly members meeting)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'b5e55e46-d474-4165-b067-4273efb0101c',
    name: ' បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ​១0 ខែតុលា ឆ្នាំ២០១៩(The consultation meeting between Czech CSOs base in Cambodia and partners)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '7248cd78-136a-4f09-a476-73c92fc9f823',
    name: ' បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ២២ ខែ មិថុនា ២០២១',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '858c7742-2189-42fb-91f7-101bcbe9e65a',
    name: '​​ស្នើសុំការលើកលែងពន្ធ',
    rate: 9.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0ac23e23-4a0c-4764-bbf1-71dd30ad973e',
    name: '​ជួលកាស',
    rate: 2.0,
    unitTypeId: '7d5b7e00-5443-4b24-9ae8-d4c8092be1bc',
  },
  {
    id: 'ecabb6d0-39db-459f-a2e4-119eaf1f54f8',
    name: '​បកប្រែបន្ថែមម៉ោង',
    rate: 50.0,
    unitTypeId: '2404414f-54a4-4b70-aaef-4013993804f8',
  },
  {
    id: '28eecfb8-21f5-4bfb-ad22-ac91fc444f03',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​៨ ៩​ និង ១០ ខែ មិថុនា ឆ្នាំ ២០២១)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '1890c9d6-f3c4-411d-8eba-7d3ca61ba557',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ០៤ ខែ មីនា ឆ្នាំ ២០២០)',
    rate: 280.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'e06637cb-b2a3-45e5-8ecd-46477e1d381d',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ១៣ ខែ ធ្នូ ឆ្នាំ ២ឥ១៩)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'ab954f38-6e98-4f77-aad7-ab69933e63e8',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ២ ខែ កុម្ភៈ ឆ្នាំ ២០២១)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '85bf0b8f-1652-470d-9312-6a3e6429dde0',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ២​៣ ខែ កុម្ភៈ ឆ្នាំ ២០២១)',
    rate: 400.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e35c1dbc-facf-43f4-a4bb-60dd1b283089',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ២១ ខែ ធ្នូ ឆ្នាំ ២០២០)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '6bc3453a-df89-486c-bd02-d883be9c865a',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ២៥ ខែ សីហា ឆ្នាំ ២០២០)',
    rate: 280.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '3fa71ebc-a028-4303-b089-0971ce87e6fc',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ២៨ ខែ  តុលា ឆ្នាំ ២០២០)',
    rate: 670.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'e0942bd2-9573-4e10-af30-3794cf3fe092',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ៣​ ៤ ៥ ៦ ៧ ៨ ខែ វិច្ឆិកា ឆ្នាំ ២០២០)',
    rate: 275.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '6ce5953a-33e4-4d93-afd6-c96ffe83ec2d',
    name: '​បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ៣០ ខែ មករា ឆ្នាំ ២០២០)',
    rate: 280.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '5283844d-ea70-47db-8764-5200a2a1634b',
    name: '​បកប្រែផ្ទាល់មាត់ បន្ថែមម៉ោង',
    rate: 150.0,
    unitTypeId: '2404414f-54a4-4b70-aaef-4013993804f8',
  },
  {
    id: '0578057d-579f-4462-a41e-230bee44118c',
    name: '​ប្រអប់សម្រាប់អង្គុយ',
    rate: 15.0,
    unitTypeId: '7d5b7e00-5443-4b24-9ae8-d4c8092be1bc',
  },
  {
    id: '1af02591-1001-40fe-993a-11516dd56948',
    name: '​ប្លង់ដី',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '41ee7f3c-504a-4103-abd1-6e03620a277a',
    name: '​លិខិតនៅលីវ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a222ffdb-dadf-4620-b509-0106b2b81eeb',
    name: '​លិខិតស្នើសុំ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b8f664e4-0c29-4b4d-9fc4-6ae8d7309c8a',
    name: '​សំណើសុំទទួលស្គាល់សហគ្រាស',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1d7dca20-591f-4be4-b436-6915025b75a6',
    name: '​ឯកសារចិន',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '369966de-aa2a-47be-9ee0-01bba73bf81f',
    name: '​ុំឯកសារក្រុមហ៊ុន ចិន អង់គ្លេស',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c5378681-5c17-4b7e-8166-cba24f384821',
    name: '(CFRS for NFPEs) Tier 2 (Final Draft)',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '952873dd-4c90-4925-aea8-9ca28c68dd57',
    name: '1.\tQuestionnaires for survey ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f6de6b53-afb3-417f-8139-4f2acc3f62bd',
    name: '180417_Social Context_Learning Note',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '387ba13b-3023-4078-97da-fd88e5667267',
    name: '19/03/2019 បកប្រែផ្ទាល់មាត់ (អ្នកបកប្រែទី១)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'a5ce221a-3cb8-4e0c-91b6-9abd0024d169',
    name: '19/03/2019 បកប្រែផ្ទាល់មាត់ (អ្នកបកប្រែទី២)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'b2944fa2-d84c-4ad6-904c-3d4dc9b95229',
    name: '19/03/2019 បកប្រែផ្ទាល់មាត់ (អ្នកបកប្រែទី៣)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'b014b557-bf72-4c10-8b60-c74c0342fbd7',
    name: '2018 Annual Cumulate Narrative Report 20190131',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'bd87f27a-aecc-4588-99e9-2595746907ff',
    name: '20190121201239_030 សេចក្តីជូនដំណឹង NGO',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fc20b2c8-1629-4c5b-961c-954b156e3551',
    name: '41-Bollore code of conduct ENG',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c335e566-98a8-44b4-987e-554cc7959d96',
    name: '5 -- Order Granting Ex Parte Application',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3310dde5-f3bc-4ea3-9c52-acfbc967b149',
    name: 'A starting point for Accessible Sanitation',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'cb274be4-e4d6-4d48-8078-95021a6abec0',
    name: 'A2A Letter to MOE - executed',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a7917d9f-6be4-4e2d-a3e6-d6acdf17e2c6',
    name: 'Admin paper',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'db3e30f0-9f4e-42a0-9137-e0f78afc9083',
    name: 'Administrative paper ',
    rate: 5.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7fc96496-8607-4ec5-90d4-d1d2d3bfc044',
    name: 'Agreement',
    rate: 13.2,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '23895908-9dc2-4f9f-890f-968d1342aa05',
    name: 'ANNOUNCEMENT',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '07ce18c8-30af-4b18-8cf0-c963949c01b3',
    name: 'Bill of Quantities and Valuation Table for Subsection Item Projects ',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fd04dc72-5c31-48b4-be43-ce7b5a5559a0',
    name: 'Brochure',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '88bcd7d9-57c7-4a23-8174-be8403c9681d',
    name: 'BVI_FORTRON未用印_Memorandum_and_Articles_of_Association.pdf',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '86fbec4a-dfa4-48e9-8016-601274e702cc',
    name: 'Cambodia Tax Booklet 2020-V2',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2574e26b-2c47-43cd-86c0-58483bbc3b0b',
    name: 'Career Development',
    rate: 6.75,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '254b0542-2f26-4ca8-83a1-0a16e38ba821',
    name: 'Certificate',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '339cb2a7-a57a-481f-9436-a07350735995',
    name: 'Civil Society Organisations Enabling Environment (EE) and Inclusive Partnership (IP) Cambodia',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2ca778ce-cc21-4a41-b017-9b7019f07673',
    name: 'COLLABORATION AND CO-OPERATION AGREEMENT',
    rate: 21.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c9512c06-d22d-4b27-b688-004e59788658',
    name: 'Colleague Handbook ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '193431fc-b562-4b7e-9765-a352933b64a5',
    name: 'Conflict of Interest Policy-PNN-KPT_Done',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd041f12e-83a1-439a-8573-8f229196e13c',
    name: 'CONSTRUCTION CONTRACT No.: 03/HDXD2019',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2d53b3d3-b285-407b-8e1b-7fdff3385df4',
    name: 'Copy certification of birth',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7039b690-e17a-4ff0-8f3b-8f39d37ca028',
    name: 'Decision regarding NCAC jurisdiction dated 24 October 2018 (អង់គ្លេស-ខ្មែរ)',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'eb52643f-3dae-497d-896c-248b8f306079',
    name: 'Decision regarding NCAC jurisdiction dated 24 October 2018 (អង់គ្លេស-ចិន)',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ee5c2338-e8e3-4292-a4da-32e0229228f8',
    name: 'Design Adobe Photoshop',
    rate: 25.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1550d96b-443b-4d87-afee-d20a14dce236',
    name: 'Document (Thai-Khmer)',
    rate: 8.33,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a6984c45-ad3c-4b19-8bca-e7ad99c081cc',
    name: 'Document list',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '80a46652-7859-40c5-ad7d-adfff178859d',
    name: 'EDITED_Angkor_Premium_Beer_kh',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '10d4dc37-4acc-491f-94cf-b2581d9958c3',
    name: 'F&F33 Community Relocation Sites Version 5-K, Kh,R_Isaac',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '83e7a50b-2b2a-403b-8cd6-88b4af794ec7',
    name: 'Fact sheet',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '643f2807-5f9f-4cc2-b41e-a8c5c9f34c99',
    name: 'Field Note Prey Veng_Province Working Group (PWG)',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fca7be02-ab06-455e-8eb5-90f8e165aaca',
    name: 'Fire & Safety',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b9b476b3-6279-42f9-bb32-00ad7cbc74eb',
    name: 'Fortron_Resolution_BVI.pdf',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a3017c13-453c-4374-912c-d41fa4b1b4ab',
    name: 'General Guidelines and Procedures',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4be60f5b-a914-4d32-af83-21673191e79e',
    name: 'Globabl Principles on National Security (គោលការណ៍សកល ស្តីពីសន្តិសុខជាតិ)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '89cbfaf9-61c6-474f-8562-c72cbc4abaa7',
    name: 'Google Prod 3970534 (003)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8ec8fcf0-aa73-4022-ba36-b3fd813f39e7',
    name: 'HKL - Covid-19 employee tracking circular Translation English to Khmer',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8daf7797-e576-4d52-9b01-c3041fe27564',
    name: 'HKL - Sustainability e-course translation English to Khmer',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0e44b33d-2df7-45b4-b2c9-0b711048a814',
    name: 'HKL- EN Script | Dialogue | 13 SEP Translation English to Khmer',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'bd836908-b611-45fe-82f9-0b1a9abf98f1',
    name: 'HK-WIN STAR - Memorandum and Articles of Association.pdf',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8b04c8cf-ea0b-40ec-abe1-9519f70309fa',
    name: 'HUI SHEUNG YIN DANIEL PROFESSIONAL EXPERIENCE',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7c90fc38-cade-4b89-ac68-298064013b9f',
    name: 'Input from Civil Society Organizations into the Amendment to Law  on Association and Non-Governmental Organizations (LANGO)',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1ffb8756-db13-414b-bf75-b81d311ec128',
    name: 'Insurance document (Vietnam to Khmer)',
    rate: 18.75,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '80811b3a-4212-4ad4-a60e-4f81d4874054',
    name: 'International Research Journal of Applied Finance',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a53ab1c7-a993-4cd6-9bf7-86384682f94e',
    name: 'Interpretation Service (សេវាបកប្រែផ្ទាល់មាត់)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '165cad94-0b8d-4943-8843-76222fca582e',
    name: 'JACKSON TSE EDUCATION (Urgent Service)',
    rate: 22.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'efe3035e-7519-43b4-91d0-606f2c9ec6fe',
    name: 'Japan invests 521 million USD ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '896d765b-2b55-410f-b164-5cffcfc90169',
    name: 'JOB & CAREER (2)',
    rate: 4.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'd6582740-3d77-4e70-bd75-893dc3d20643',
    name: 'Jordan Tse Resumé (ចិន-អង់គ្លេស)',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'bee7640f-66a9-49db-894f-6a7f9bda3cfd',
    name: 'Khmer ID',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '03436a7b-9fe9-4865-89a1-c65f227c9326',
    name: 'Knowledge Management Policy-KPT_Done',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '65f4e412-1a07-4e3a-acc3-a4a0a2c788b7',
    name: 'Land ownership application',
    rate: 8.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd510cccf-7080-48ac-a1f9-05b770dc629a',
    name: 'LAND TRUST AGREEMENT',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c88ff447-73ec-4be0-ae3b-e696f0a8d93f',
    name: 'LB_Standing-Order',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e94a1c7c-007c-441a-aa7c-abbde01da964',
    name: 'Lease agreement Kh-Ch',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4c04b699-6ff7-49ce-95a2-abdd51892449',
    name: 'Life Skill Assessment Tool',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ae2111c7-bc24-4158-a13e-c9335338e7b7',
    name: 'Life Skills Assessment Pilot Tool',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '25dde76a-e7b2-4f17-bb7d-2a485a631dd4',
    name: 'Marriage Certificate',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd789cebf-ffbf-4747-9884-7002cd3d0875',
    name: 'Menu (Chinese to English)',
    rate: 4.0,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: '1e259aad-d82c-417d-b532-f8493c5b965a',
    name: 'MHPSS Lay Counsellor Training for SW Thai Cam PART 1 ',
    rate: 5.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'e2024d47-2816-4b87-bf4f-89fc34b1a770',
    name: 'MHPSS Lay Counsellor Training for SW Thai Cam PART 2 ',
    rate: 5.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: '52598b73-76f2-48e1-9e90-504cd8df6cd8',
    name: 'Municipal Waste Renewable Energy Power Generation Project Proposal',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '802aaa8d-f19d-49e8-9da4-0b9b013b91da',
    name: 'No 23913-Letter from GDT on 27 Oct 2020',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '089865b8-df51-4f23-a603-79c81903a5e1',
    name: 'Online Database Guideline_Done',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'cabeafac-79e9-49ce-93ef-b8d6971f2b65',
    name: 'P&R របាយការណ៍សង្ខេបលើកទី ៥',
    rate: 5.0,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'b600c4e9-bb51-4484-bc81-9f1ac5ee1ade',
    name: 'PNN Strategic Plan 2018-2020-KPT_Final_Done',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f383a2f6-8704-4a6f-9f6d-d77e63932da7',
    name: 'Rental Agreement',
    rate: 12.5,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8166f249-6f6b-4cde-b383-a5a4baaf6f8b',
    name: 'Residence Record',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd0f3efb8-5430-48af-9c5b-59700e8161ce',
    name: 'Resolution of the General Meeting of Shareholders',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '64eb3e22-536c-4197-bcd8-5e83d87ead55',
    name: 'RESPONSE',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3da90535-4d61-43a3-9752-31cb3ed46f32',
    name: 'Script English to Khmer',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '095e005a-9a4d-4723-bd38-84c81abb1463',
    name: 'Security',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '652f817f-cef4-45cc-9e5a-1265b9a15d27',
    name: "Shareholders'​ Agreement",
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1491399c-7d87-42e0-994b-78dc4de35cec',
    name: 'Short Narrative SC Wash Final',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'be847f36-05ab-4683-bffb-6fb9115517d7',
    name: 'SSKKB_Residents Offer_Landing Page_Cambodian 2020',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '145700ce-8420-434b-a059-2be4ed8940e0',
    name: 'Sub-decree on Regulation 4 Foreign Migrants2016',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b3c9b5d3-e261-4d8d-96fb-2416b5cd85d1',
    name: 'Sub-Decree on The Establishment of the National Internet Gateway',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'cad3fa48-ba3e-4638-a1ef-2394daff0ea1',
    name: 'Terms of Reference for Formative Research',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6d680abb-f89b-4be8-bfb4-9eb2cc4b46b7',
    name: 'The Grow Model',
    rate: 4.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'c8e00bb5-2859-428a-b335-b6878a0a7181',
    name: 'The Role of Government Led Advocacy in CRSHIP',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fbfea65f-410d-43e8-9117-4af7cd6ba8cd',
    name: 'Top ten basics of Feminist Leadership ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '47e26a00-357e-4d70-b7f8-96691a2ffeab',
    name: 'TOR for PNN Committee-PNN-KPT_Done',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4f7cf4d2-99be-4eac-a43f-2a48917e9a94',
    name: 'TOR-PNN-KPT_Done',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '57a738ee-9c84-433d-be49-4661213d6800',
    name: 'TPO SELF CARE TOOLKIT (May 30)',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '28e41d12-5018-42aa-ae36-6795ded779b7',
    name: 'Trafific Version 4-checked by K, K, R_Isaac',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1d49ecef-9ff7-4a86-a1da-0fb026eec2e5',
    name: 'TRANSIT Smart Guide',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ad8f21dd-ea54-4954-9208-ef0fcaa525ba',
    name: 'Translation (English to Khmer)',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4d40b3e8-7831-45e9-9a2b-ee30b74b37dc',
    name: 'Trust-Law and Labour Law Amendment',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ab510206-05f8-4492-a300-85452f2df090',
    name: 'TWG TEA | TIME FOR TEA Brand Film',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ec06c14c-f0ce-4247-9757-9ba9fc8909c1',
    name: 'TWG TEA presents DARJEELING Land of Thunder A Tea Origin Story T1 - T29',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '92b6cc25-2110-4958-841f-fc011a42cf61',
    name: 'Vattanacville_PressRelease_KKS_Final',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '088b5fab-e485-488f-a42b-4b05800f2d65',
    name: 'Verdict',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c195dbdf-fd71-4668-ab88-bd9657bef7a9',
    name: 'Web content EN - CH',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a8e11075-b67f-4489-b9cb-6bd8757a0c90',
    name: 'Website copy',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6a765207-cdb3-4862-8098-90c245e262ee',
    name: 'Win Star_Minutes of Meeting_HK.pdf',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c85491cf-43db-406a-88b1-8857adb1fea9',
    name: 'កញ្ចប់រដូវក្តៅសម្រាប់ប្រជាជនកម្ពុជា ២០២០',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fa57a7fc-bd44-4a3b-bc38-ff5774812005',
    name: 'កម្រងសំណួរស្ដីពីការសម្ភាសការលាឈប់ពីការងារ (EXIT INTERVIEW QUESTIONNAIRE)',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4b8c8cf1-cb72-4370-ba9f-ed9d285835e6',
    name: 'ករណីគម្រោងប្លង់មេ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2ec42304-b4a5-4374-9c17-9e05f5f7e600',
    name: 'ករណីសំណើសុំលិខិតឆ្លើយតប',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ecf1ca0e-103a-48ae-a504-ebbafe1c8262',
    name: 'ករណីសំណើសុំវិនិយោគ ​១',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd2511cce-6434-4920-9031-80b74404d264',
    name: 'ករណីសំណើសុំវិនិយោគ ​២',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7e63ea7d-a3f8-4924-b97a-a645cd4c1b50',
    name: 'ការគ្រប់គ្រងអាជីវកម្មធានារ៉ាប់រង និង លំហូរនីតិវិធីដោះស្រាយបណ្តឹងតវ៉ារបស់អតិថិជន',
    rate: 5.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3c61a418-c096-40c7-886d-55fb4e96a005',
    name: 'ការតាមដានសកម្មភាពតស៊ូមតិសហគមន៍ក្នុងរាជធានីភ្នំពេញ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '40a4e3a3-9f6e-4e59-95e9-b6f1ddf3a998',
    name: 'ការបកប្រែមាតិកាគេហទំព័ររបស់គណៈកម្មាធិការអន្តរជាតិកាកបាទក្រហម ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ef07c3db-709e-49a7-a8b8-7bea45e49e24',
    name: 'ការបកប្រែវីដេអូ',
    rate: 420.0,
    unitTypeId: '2404414f-54a4-4b70-aaef-4013993804f8',
  },
  {
    id: '5fb36275-1c60-42fa-971e-14f11c536f61',
    name: 'ការបញ្ចប់កិច្ទព្រមព្រៀងជួលផ្ទះ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '454c8c51-b2fe-4cac-91a9-a44ccac879a9',
    name: 'ការពង្រឹងសង្គមស៊ីវិលដើម្បីការអភិវឌ្ឍតាមបែបប្រជាធិបតេយ្យប្រកបដោយនិរន្តរភាពនៅកម្ពុជា',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e269f3cf-61e4-4d9c-9b21-5a66581f3fc9',
    name: 'ការសន្ទនា',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '66340e55-62ab-4b22-8da3-4d7cb74cfe5b',
    name: 'ការសហប្រតិបត្តិការរវាង UNDP & Green Leader អង់គ្លេស-ចិន',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7bf405e6-6b08-4615-a6ee-33d728e9b7c8',
    name: 'កាសស្តាប់ត្រចៀក',
    rate: 2.5,
    unitTypeId: '7d5b7e00-5443-4b24-9ae8-d4c8092be1bc',
  },
  {
    id: '06fedcb3-d77a-4b27-84b3-f0a4aa8321f2',
    name: 'កិច្ចប្រជុំប្រចាំឆ្នាំ លើកទី២៩',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c9de968b-75e2-4c90-b029-b9f1b4546565',
    name: 'កិច្ចព្រមព្រៀង សហប្រតិបត្តិការ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd4f26670-91f1-470a-a54a-6bbb8be1bbbe',
    name: 'កិច្ចព្រមព្រៀង​',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '926eaf4f-2a1f-4914-9026-77b05c15c2f9',
    name: 'កិច្ចព្រមព្រៀងជាវសេវាកម្ម សូហ្វវែរ (SaaS) ',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b63281b3-3a41-46f8-850d-2575f06fac6c',
    name: 'កិច្ចព្រមព្រៀងជួលនេះ ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '30f2a1e1-876a-4f75-a95e-baa0d92826ee',
    name: 'កិច្ចព្រមព្រៀងដាក់បញ្ចាំ',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b25793c0-8f6d-4637-9512-c620219f6749',
    name: 'កិច្ចព្រមព្រៀងទិញភាគហ៊ុន',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7c02a6c0-53fe-42ee-bca2-9977d1b554d8',
    name: 'កិច្ចព្រមព្រៀងទូទាត់ប្រាក់កម្ចី',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b1305ece-0720-4233-a5ef-728c2d459549',
    name: 'កិច្ចព្រមព្រៀងភតិសន្យាហិរញ្ញវត្ថុ',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5a2a1bb6-f67f-4342-84a9-ede62590ecf2',
    name: 'កិច្ចព្រមព្រៀងសេវាណែនាំអតិថិជន',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd3a9ebf7-91b1-4d99-abac-85322f7aebf1',
    name: 'កិច្ចព្រមព្រៀងស្ដីពីកម្ចីឥណទាន 3',
    rate: 13.64,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8450372b-35e7-49f7-a168-aa847286845f',
    name: 'កិច្ចព្រមព្រៀងស្ដីពីការធ្វើលំហែទាំ និងកិច្ចសន្យាជួលប្រព័ន្ធថាមពលពន្លឺព្រះអាទិត្យ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a04e8dd1-1cba-4dcb-afe7-7e2899fb7249',
    name: 'កិច្ចព្រមព្រៀងស្ដីពីការផ្សព្វផ្សាយពាណិជ្ជកម្ម (ឡានក្រុង)',
    rate: 9.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c99e88eb-1c3a-4c32-9b53-74de480f2c13',
    name: 'កិច្ចព្រមព្រៀងស្ដីពីការផ្សព្វផ្សាយពាណិជ្ជកម្មជាមួយ ម៉ូតូ រ៉ុក់ម៉ក់កង់បី (តុក តុក)',
    rate: 9.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0f52e720-fd0f-404f-bea0-cbbbf36a990e',
    name: 'កិច្ចសន្យា ',
    rate: 35.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b33fa9cf-3dd6-4067-9343-7cf2015b2b5c',
    name: 'កិច្ចសន្យា (Agreement)',
    rate: 12.73,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b505a2c2-51d9-4686-85d8-9d1c623c00e9',
    name: 'កិច្ចសន្យា ប្រគល់-ទទួល ប្រាក់កម្រៃសេវា និងយោបល់-ការពារផ្លូវច្បាប់',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3317b026-5e01-44a9-b2a5-e1a2ac15531a',
    name: 'កិច្ចសន្យា អង់គ្លេស-ចិន',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4d13b013-a91d-4c4b-893e-c6cbe8acc081',
    name: 'កិច្ចសន្យាការងារ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '156dd287-f3a2-4602-850e-db67f56da484',
    name: 'កិច្ចសន្យាការពារបរិស្ថាន',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e7dfd591-a13f-4115-b51b-2d631c0eccbb',
    name: 'កិច្ចសន្យាខ្ចីបរិភោគដោយភ្ជាប់ការប្រាក់',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a2b9925b-01a1-40d4-b1e6-4f1640f8a01f',
    name: 'កិច្ចសន្យាជួល',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '24b3f0f4-546e-4b6e-9f1a-3661a94af992',
    name: 'កិច្ចសន្យាជួល (Lease Agreement)',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ddf14b20-8794-4bd2-8e38-cfee5513d917',
    name: 'កិច្ចសន្យាជួលការិយាល័យ_និងបន្ទប់',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0c8b0ff6-ddc5-49de-bdf2-885d07535e93',
    name: 'កិច្ចសន្យាជួលដី',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '26006e09-d3f2-4bb3-8feb-30ffff619ef2',
    name: 'កិច្ចសន្យាទិញ-លក់ (ឯកសារកែសម្រួល)',
    rate: 8.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '841cec49-ac39-4fee-a00a-607d15982744',
    name: 'កិច្ចសន្យាបកប្រែ ខ្មែរ-ចិន',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5533b6f8-4b34-4d4c-888a-5c8cf6453f62',
    name: 'កិច្ចសន្យាបង្កើតហ៊ីប៉ូតែក',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b1a4245c-4cbc-45d0-8954-d90de095a010',
    name: 'កិច្ចសន្យាបញ្ចប់',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c814d613-e62e-48b5-a56f-02be5ab9e677',
    name: 'កិច្ចសន្យាប្រតិបត្តិកា',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '96c062ae-0f16-4dd7-a10b-a6867dc40bf6',
    name: 'កិច្ចសន្យាផ្គត់ផ្គង់ និងដំឡើងបរិក្ខារជាឈុត សម្រាប់ខ្សែចង្វាក់ផលិតកម្មម្សៅដំឡូងមីអន្ធិលកម្ម ដែលមានសមត្ថភាពផលិត ៥០ តោនក្នុងមួយថ្ងៃ',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '9de638fa-4281-4014-a6e2-a17243fa46b1',
    name: 'កិច្ចសន្យាស្តីពីការត្រួតពិនិត្យតាមដាន និងការធ្វើលំហែទាំ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '814d9bc8-d00e-4a9d-ba89-393220290e2b',
    name: 'កិច្ទព្រមព្រៀងទិញលក់​ និងតម្លើងជណ្តើរយន្ត',
    rate: 13.6,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f37328c5-0a3d-417e-ba7e-b53e5d9b86f6',
    name: 'កិច្ទសន្យា',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2f72efb7-d122-40b7-a746-a5dfb85ffd2d',
    name: 'កិច្ទសន្យា​ភាសារចិនទៅខ្មែរ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '68565c94-f9ea-484f-ae21-e28b0c1cafe3',
    name: 'កុងត្រា',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '580129b0-ba6a-45bd-99a3-870dec7c5e97',
    name: 'កុងត្រា (ចិនបកទៅអង់គ្លេស)',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0c6eff10-9567-4990-ab65-b8ceda478fe4',
    name: 'ក្រមសីលធម៌',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1e5aebce-c64d-4530-978c-bec5260d1dd2',
    name: 'ក្រមសីលធម៌វិជ្ជាជីវៈ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fc2c4bb4-cd8d-464e-8d01-e3833e86fd4e',
    name: 'កំណត់ហេតុកិច្ចប្រជុំ  គណៈកម្មាធិការប្រតិបត្តិអង្គការ គ.ស.ក',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a4e1e5d8-b597-4543-a8d9-5efedcd1270a',
    name: 'កំណត់ហេតុកិច្ចប្រជុំ TWG-A2I លើកទី ១៤ (Minutes of the 14th meeting of the TWG-A2!)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '56541d30-d855-4189-8e14-2e1387352daa',
    name: 'កំណត់ហេតុកិច្ចប្រជុំ TWG-A2I លើកទី ១៥ (Minutes of the 15th Meeting of the TWG-A2I)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7d3ae595-d5d9-4725-8a4e-916ec59c0dc0',
    name: 'កំណត់ហេតុកិច្ចប្រជុំ TWG-A2I លើកទី ១៦ (Minutes of the 16th Meeting of TWG-A2I)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a9331991-a859-43af-9bb6-8c0f90a8dc92',
    name: 'កំណត់ហេតុកិច្ចប្រជុំទី១១ (11th Minutes Meeting)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ac96db8d-2aa5-4094-aa7f-5608311b3be5',
    name: 'កំណត់ហេតុកិច្ចប្រជុំលើកទី ១២ របស់ក្រុមការងារបច្ចេកទេស (Minutes of the 12th meeting of the TWG)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e879ea2f-e1c6-4501-ae5b-421fe5d6991c',
    name: 'កំណត់ហេតុកិច្ចប្រជុំលើកទី ១៣ របស់ក្រុមការងារបច្ចេកទេស (Minutes of the 13th Meeting of the TWG-A2I)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '270a0d5c-2150-4c41-a322-be1670b0dada',
    name: 'កំណត់ហេតុសវនកម្ម',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '683b22bf-d630-44b5-b021-1eeb4573ef69',
    name: 'កំណែរថ្មីនៃមាត្រា ២០ និង ២១ (Revised articles 20 & 21)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b25dd4e0-ddae-40a1-83c6-c0f6dc2a093e',
    name: 'ខ និងលក្ខខណ្ឌ GS1កម្ពុជា',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ed1775a7-339c-4afc-98cb-3025065b6fda',
    name: 'ខិត្តប័ណ្ណ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '09076d91-2491-4b9b-bf49-85be74f901db',
    name: 'គម្រោងអនាម័យគំរូតាមសាលារៀន',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1135bd3f-0076-457c-bba7-d003d0421e41',
    name: 'គោល​ការណ៍​ណែនាំ​របស់​អង្គការ​ អូអ៊ីស៊ីឌី (OECD) សម្រាប់​សហគ្រាស​អន្តរជាតិ (Urgent)',
    rate: 6.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: '95ca5ade-ffc7-4b82-b392-ab5eaf1c285e',
    name: 'គោលការណ៍ណែនាំស្ដីពីការប្រតិបត្តិល្អរបស់អង្គការសហគមន៍មូលដ្ឋាន',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ba97e583-5aca-43d8-8b14-e25b6f0ce326',
    name: 'គោលនយោបាយក្តោបរួមស្តីពី ការបៀតបៀន ការកេងប្រវ័ញ្ច និងការរំលោភបំពានផ្លូវភេទ (SHEA) និងក្តីបារម្ភអំពីការការពារផ្សេងទៀត',
    rate: 9.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '44deec5d-1e48-4d79-b9fd-4123d145c4f7',
    name: 'គោលនយោបាយប្រឆាំងការធ្វើទារុណកម្ម និងការជួយដូរមនុស្ស',
    rate: 9.09,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0ba0d5bb-904b-4c6c-8525-cde51f272798',
    name: 'គោលនយោបាយរាយការណ៍',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5e1588be-a900-490c-9732-4446fe915247',
    name: 'គោលនយោបាយរាយការណ៍របស់អង្គការអាក់សិនអេត',
    rate: 9.09,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '486af684-7b8c-436e-9e18-335a090cd201',
    name: 'គោលនយោបាយសម្រាប់ ការរំខាន និងការបៀតបៀននៅកន្លែងធ្វើការ',
    rate: 9.09,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4828d054-ea51-4d79-b05a-b0509a1b86dd',
    name: 'គោលនយោបាយស្តីពីការការពារកុមារ ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '652dfb9e-4136-410d-85fd-d3a830e12e9f',
    name: 'គោលនយោបាយស្តីពីការគាំពារពីការកេងប្រវ័ញ្ច និងរំលោភបំពានផ្លូវភេទ (PSEA)',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6f60b991-5398-4ca2-a2a9-8a5b9bdacd6e',
    name: 'គោលនយោបាយស្តីពីការណែនាំ ប្រតិបត្តិការ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8d4b3f97-4b65-46aa-9031-68ce640727f5',
    name: 'គោលនយោបាយស្តីពីហិរញ្ញវត្ថុ ខែមករា ឆ្នាំ២០១៩',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ec88ee90-e2ef-4a60-a3f9-da7944ea0ab4',
    name: 'គោលនយោបាយឥណទាន',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8aa8d17f-2e63-4243-9ff0-233ed3efa930',
    name: 'គំរូ  លក្ខន្តិកៈ  អង្គការ (ខ្មែរ-អង់គ្លេស)',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b5bf9a75-6fb0-4450-9aa5-36b8f3340df6',
    name: 'ចម្លើយរបស់សាក្សី',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '27f660d2-0670-47b9-accc-ed582d6f9b8b',
    name: 'ច្បាប់ជាមូលដ្ឋានស្ដីពីសេរីភាពនៃការបញ្ចេញមតិ (The Fundamental Law on Freedom of Expression)_Sweden',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6253ffe6-f53f-471b-bf31-de2a9061bc71',
    name: 'ច្បាប់នៃទ្រព្យ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f177ae49-dfa1-4fd9-a497-120cd9932436',
    name: 'ច្បាប់នៃសាធារណរដ្ឋឥណ្ឌូណេស៊ី ស្ដីពីការបើកចំហពត៌មានសាធារណៈ(Act Of The Republic Of Indonesia On Public Information Openess)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'adf6eae4-0574-4fc0-a1a5-6f465856005d',
    name: 'ច្បាប់ស្ដីពីសិទ្ធិទទួលបានព័ត៌មាន និង ការសម្ងាត់ជាសាធារណៈ (Public Access to Informaiton and Secrecy Act)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd3f9d410-62ef-4d86-8244-4ed9e633aa57',
    name: 'ច្បាប់អចលនទ្រព្យ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2e19cec9-c446-4675-9836-98e555bc38c7',
    name: 'ជួលឧបករណ៍ស្ដាប់សម្លេង (Booth និង Earphone)',
    rate: 52.5,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '5b75da76-dc0f-4a26-8675-db3e7599fd8e',
    name: 'ដីកាកោះ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7f5609d8-797a-406b-96e2-9ec5819bd0a1',
    name: 'ដីកាបញ្ជូន និង របាយការណ៏សំឃោគ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'daf0eb25-142f-40b3-b03f-f28b64befaf2',
    name: 'ដីការកោះ',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '54e9327a-cde1-4f80-888b-dc4a7aa8e08d',
    name: 'ដីការតម្កល់រឿងទុកឥតចាត់ការ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '9ed15897-a512-4193-aa34-581b448a317f',
    name: 'ដីការបញ្ជាអោយចូលខ្លូន',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '26b4f614-5e37-44e5-ac93-7198275ff534',
    name: 'ថតចម្លងឯកសារ',
    rate: 1.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '01974b3c-f614-4496-bb52-d167ccc06298',
    name: 'ថ្លៃស្នាក់នៅនិងថ្លៃអាហារ',
    rate: 25.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '378f01db-26b5-43e2-be7b-f5ae3c1256e7',
    name: 'ទម្រង់​ឯកសារទាក់ទងជំងឺកូវីដ-១៩',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e6bb0887-22ad-4a11-b59d-0e4c9544ccfc',
    name: 'ទិវាអង្គការសង្គមស៊ីវិល',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0f3e4c30-ad34-44f6-9e63-8537ceadc56b',
    name: 'និយមន័យ (Definitions)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fd1d1144-6368-4bd2-878b-910bc08eb221',
    name: 'បកប្រែផ្ទាលមាត់ (១៩ កុម្ភៈ ២០២០ )',
    rate: 280.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '1c533de0-bf3a-48c2-b3a0-44b95ce35652',
    name: 'បកប្រែផ្ទាលមាត់ 05/10/2017',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '54438a45-3584-4a9e-93ca-21aacb52f53e',
    name: 'បកប្រែផ្ទាលមាត់ 06/12/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '25b5dbf8-488e-4f62-8912-606090172659',
    name: 'បកប្រែផ្ទាលមាត់ 07/12/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'c4382b36-5a1c-41ab-aa28-e2427111c116',
    name: 'បកប្រែផ្ទាលមាត់ 11/12/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '2c3012fe-233b-448f-bacf-6b8c8b53ef6e',
    name: 'បកប្រែផ្ទាលមាត់ 13/12/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '34f84bf1-14ac-409d-987c-c276698680e0',
    name: 'បកប្រែផ្ទាលមាត់ 14/11/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '8a6d5a21-2367-4129-8c89-701d4d8c63f1',
    name: 'បកប្រែផ្ទាលមាត់ 18/03/2019',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'e80983a7-4bb0-47c1-8d18-54c2e9264244',
    name: 'បកប្រែផ្ទាលមាត់ 23/01/2019',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '0925382a-8177-4f88-bd38-bdd3b7329d89',
    name: 'បកប្រែផ្ទាលមាត់ 24/10/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'bf855988-c016-4a2b-87bd-c4cfac89e606',
    name: 'បកប្រែផ្ទាលមាត់ 28/09/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '240de956-62a5-4446-b41d-5cf2814d2ac5',
    name: 'បកប្រែផ្ទាលមាត់ 29/06/2018',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'b81b26fb-ec1d-468a-a433-55e7b9197e50',
    name: 'បកប្រែផ្ទាលមាត់ 30/01/2019',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '4d64de53-a373-4885-b77c-00e3abd2977e',
    name: 'បកប្រែផ្ទាលមាត់កន្លះថ្ងៃ (02/08/2018)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '9d4fc1b6-11f5-40e3-a791-2f73b7ce7e4e',
    name: 'បកប្រែផ្ទាល់មាត់',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'e7e68ca6-f363-4b98-bc53-4504224c39ef',
    name: 'បកប្រែផ្ទាល់មាត់  ថ្ងៃទី​ ១០ មេសា ២០១៨',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '292a6c5d-9855-48bc-be53-9cd02a27a1bb',
    name: 'បកប្រែផ្ទាល់មាត់  ថ្ងៃទី​ ១១ ខែមិនា ឆ្នាំ ២០១៩',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '7f5ed581-ed7f-4491-af26-99b16897a404',
    name: 'បកប្រែផ្ទាល់មាត់  ថ្ងៃទី​ ៥ មេសា ២០១៨',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '24e37fa5-acd3-4f24-bf94-1c7b022af7fa',
    name: 'បកប្រែផ្ទាល់មាត់ (០៥ កញ្ញា ២០១៧)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'b5e44e58-9066-44ca-9336-09876ca0b532',
    name: 'បកប្រែផ្ទាល់មាត់ (០៧/១២/២០១៧)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'f0fd0a87-748a-4e9f-b5c0-2a82a8cb47b0',
    name: 'បកប្រែផ្ទាល់មាត់ (08/09/2017)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'eae57365-a235-49e1-8099-d11c9eafc274',
    name: 'បកប្រែផ្ទាល់មាត់ (28 មករា 2019)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '93b19133-7218-4167-a944-17fbd02c7a78',
    name: 'បកប្រែផ្ទាល់មាត់ (29/01/2019) អ្នកបកប្រែទី ១',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'ccf5a0fa-d3d6-4777-9f9d-e7b05faa92a2',
    name: 'បកប្រែផ្ទាល់មាត់ (29/01/2019) អ្នកបកប្រែទី ២',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '6c0760a0-dc2e-4d86-852e-6d46b1a56a44',
    name: 'បកប្រែផ្ទាល់មាត់ (កន្លះ​ថ្ងៃ)',
    rate: 240.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'a5d96448-4bc7-4b88-a573-6bdbe3a79049',
    name: 'បកប្រែផ្ទាល់មាត់ (កន្លះថ្ងៃ)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '10525dd1-8b41-4fad-8d45-7744e6fef6e2',
    name: 'បកប្រែផ្ទាល់មាត់ (ថ្ងៃ ២៣ ខែមេសា ឆ្នាំ២០១៩)',
    rate: 350.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '5f0ee2dd-023f-47b6-8899-3a93d50ec4c2',
    name: 'បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ​២៦ ខែសីហា ឆ្នាំ២០១៩)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'e769b8f3-594e-4fee-8ec5-8266f986c154',
    name: 'បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ០៥-០៦ ខែមិថុនា ឆ្នាំ២០១៩)',
    rate: 225.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'ecf8f327-b683-4a2e-8cc1-bc90d7572804',
    name: 'បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី ២៣ ខែ កញ្ញា ឆ្នាំ ២០២០)',
    rate: 400.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '5bdf4806-8ed2-472f-a449-15b2391772cf',
    name: 'បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី​ ១២ ខែធ្នូ ឆ្នាំ ២០១៩ ) ',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '4be85829-f11a-49af-a6e6-0863c60bdb97',
    name: 'បកប្រែផ្ទាល់មាត់ (ថ្ងៃទី១៨ ខែកក្កដា ឆ្នាំ២០១៩)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'd575d984-291f-4a91-a2c7-5c2dabf3317d',
    name: 'បកប្រែផ្ទាល់មាត់ (សម្រាប់អ្នកទីបី)',
    rate: 40.0,
    unitTypeId: '2404414f-54a4-4b70-aaef-4013993804f8',
  },
  {
    id: 'd428bed8-f96c-4143-a628-254faccd54ff',
    name: 'បកប្រែផ្ទាល់មាត់ (សម្រាប់អ្នកទីពីរ)',
    rate: 40.0,
    unitTypeId: '2404414f-54a4-4b70-aaef-4013993804f8',
  },
  {
    id: '293517cb-9eda-4180-82eb-773caa2c83e3',
    name: 'បកប្រែផ្ទាល់មាត់ (សម្រាប់អ្នកទីមួយ)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '4ea67810-4f05-48fd-99cc-df6a4532dea4',
    name: 'បកប្រែផ្ទាល់មាត់ (អ្នកបកប្រែទី​បី) Breakout Session',
    rate: 200.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '4c57e37d-2478-43c2-9737-224f9dadac70',
    name: 'បកប្រែផ្ទាល់មាត់ (អ្នកបកប្រែទីពីរ) Breakout Session',
    rate: 200.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '21e8689b-7f2b-4f62-8bf5-fbc7c837463e',
    name: 'បកប្រែផ្ទាល់មាត់ (អ្នកបកប្រែទីមួយ) Full time',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '87a7b57e-ad1a-4114-91d6-397c47e2f329',
    name: 'បកប្រែផ្ទាល់មាត់ 01/07/2019',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '653c7ae4-0671-4743-a07b-c88ecfd07c89',
    name: 'បកប្រែផ្ទាល់មាត់ 02/07/2019',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'c9e8dbc0-5627-4c3c-a38b-a13ca3f69557',
    name: 'បកប្រែផ្ទាល់មាត់ 08.05.2019',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'd2b959fe-cdd5-4182-86de-4f387889a878',
    name: 'បកប្រែផ្ទាល់មាត់ 15/12/2017',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '3aadc74d-8482-4af5-95ec-d0c5bebf9605',
    name: 'បកប្រែផ្ទាល់មាត់ 17.11.2017 (កន្លះថ្ងៃ)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'c52737af-4714-45d0-bc37-ec69842b2927',
    name: 'បកប្រែផ្ទាល់មាត់ 19.10.2017 (អ្នកបកប្រែទីពីរ)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '28b77486-44c2-4db8-8abb-3306f2143ec4',
    name: 'បកប្រែផ្ទាល់មាត់ 19.10.2017 (អ្នកបកប្រែទីមួយ)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '9a7afecc-5ced-466e-b9b7-d245929a9824',
    name: 'បកប្រែផ្ទាល់មាត់ ២៧/០៩/២០១៧',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '4003141a-de67-4886-ae68-cc25bf207f24',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី  ៨ ៩ ១៩ និង ២០ ខែ កក្កដា ២០២១',
    rate: 250.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b2c457ed-f50d-4e1a-9d68-22417dc1f765',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ​០៣ ខែ  ១២ ឆ្នាំ ២០២១ អ្នកបកប្រែទី ​​២ ',
    rate: 350.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '3f53daa7-6a19-44be-a0fa-bd1025d1f10a',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ​០៣ ខែ  ១២ ឆ្នាំ ២០២១ អ្នកបកប្រែទី ​១',
    rate: 350.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '7f0d6d12-51b2-4ff8-ae4c-998974325943',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ០៧ ខែ ១២ ឆ្នាំ ២០២១',
    rate: 500.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '53a55117-d2c8-47b1-9128-e68b3eae7701',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ១៦ ខែ ១១ ឆ្នាំ ២០២១ អ្នកបកប្រែទី ​២',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'a4b656a4-4dfb-40c2-af60-ff2fbb01f61a',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ១៦ ខែ ១១ ឆ្នាំ ២០២១ អ្នកបកប្រែទី ១',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'bc109636-f64e-470a-8122-680f3210eacc',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ២០ ខែ ធ្នូ ឆ្នាំ ២០១៨',
    rate: 210.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '7109d0a4-bb63-4f1b-88b6-a35ba7e4eaa4',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ២១ ខែ ធ្នូ ឆ្នាំ ២០១៨',
    rate: 210.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '976a5950-daac-46c8-b373-c6a57f6e74ac',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ២៣ ខែ កញ្ញា ២០២១',
    rate: 600.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'bcca7b2c-7076-4322-a179-4dea2f2272eb',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី ២៤ ខែ មិថុនា ២០២១',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'f7c97fb1-c4b4-4045-bd19-00efe10d4773',
    name: 'បកប្រែផ្ទាល់មាត់ ថ្ងៃទី២៧ ខែមិថុនា ឆ្នាំ២០១៩',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '2fbd2e39-34ce-40f5-a2a4-bfa5de0d24bc',
    name: 'បកប្រែផ្ទាល់មាត់(ថ្ងៃទី ១១ ១២ ១៣ និង ១៤ ខែ មករា ឆ្នាំ ២០២១)',
    rate: 275.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '5d63f0b0-6cef-4bf5-82f2-6bc62ff631a1',
    name: 'បកប្រែផ្ទាល់មាត់នៅខេត្ត',
    rate: 275.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'e6ce3f2c-c232-4822-9ef4-8f837fccd2bf',
    name: 'បកប្រែឯកសារ',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '11c94f8e-084c-4fec-8818-0810bace9e25',
    name: 'បកប្រែឯកសារ (ខ្មែរ-អង់គ្លេស)',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '72c30394-7314-41e2-9318-57751aae74fb',
    name: 'បកប្រែឯកសារ (ចិន-ខ្មែរ)',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '944aa3d4-bf25-4f33-9889-740d130b4c8a',
    name: 'បកប្រែឯកសារ (លិខិតប្រគល់សិទ្ធិ ពីចិនទៅខ្មែរ)',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a2213d11-20f2-4b66-a429-54f59ca6aa9c',
    name: 'បកប្រែឯកសារ (អង់គ្លេស-ខ្មែរ)',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '34f98f55-ac84-489d-b493-72468eba54b1',
    name: 'បកប្រែឯកសារ (អង់គ្លេស-ចិន)',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'da859aea-e0ed-4f54-a22b-204361ca14b7',
    name: 'បកប្រែឯកសារ ចិន-ខ្មែរ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '38fdc004-3a85-4fb4-97a0-1177c8475981',
    name: 'បកប្រែឯកសារ ពីភាសាចិនទៅភាសាខ្មែរ',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8f5091fc-9803-4c36-b442-74f57438427d',
    name: 'បកប្រែឯកសារ ពីអង់គ្លេស-ខ្មែរ',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '53100cb4-e9ac-4f82-b081-adc509feb655',
    name: 'បកប្រែឯកសារ ស្លឹកតែ Olive',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0f0f8644-d14d-4c3b-8aa9-5967e585d968',
    name: 'បកប្រែឯកសារ អង់គ្លេស-ខ្មែរ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3a030d0b-2c35-4bee-9d46-57df83c3c649',
    name: 'បកប្រែឯកសារ អង់គ្លេស-ចិន',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0879d809-0500-486d-a4ae-6e07c1748785',
    name: 'បកប្រែឯកសារក្រុមហ៊ុន',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '9ada55d6-fd48-4ffa-8bfb-a3bc1cd5e34f',
    name: 'បកផ្ទាល់មាត់ (ថ្ងៃទី១១ ខែមិថុនា ឆ្នាំ២០១៩)',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: 'e20e74af-c2e2-4edb-b40c-0ef7e92ffca0',
    name: 'បងបង្ហាញស្តីពីការសម្ងាត់របស់រដ្ឋ (Presentation on State Secrecy)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3040359a-8c6d-4d9a-acbb-82f2f1290f73',
    name: 'បង្កាន់ដៃបង់ពន្ធ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b67919ff-8690-4913-8c2d-65ef1247dd51',
    name: 'បញ្ជីរាយមុខម្ហូប (Menu)',
    rate: 21.81,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ab3f53a1-a39f-4774-87dc-40a97c98af04',
    name: 'បញ្ជីសំណួរ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2814bead-d0d2-4b67-a402-e5912c96aac4',
    name: 'បញ្ជីសំណួរអំពីការវាយតម្លៃធុរកិច្ច',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e517a880-d258-4c7d-b54f-b441a67fd535',
    name: 'បទបង្ហាញទាក់ទងនឹងច្បាប់ស្តីពីបណ្ណសា (Presentation on Law on Archives)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd9e9e4c2-d139-4d17-934a-7f82123db583',
    name: 'បទបង្ហាញស្តីពីការផ្តល់សេវាសាធារណៈតាមរយៈស្មាតហ្វូន (Presentation on the Provision of Public Service Information via Smartphone)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5f51bca9-1806-4c0c-9fe3-2a902d750c02',
    name: 'បទបង្ហាញស្តីពីការសម្ងាត់សម្រាប់រឿងក្តី (Presentation on Confidentiality for Court Cases)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f51a4702-4d88-46bb-9a07-76b4649ac809',
    name: 'បទបង្ហាញស្តីពីព័ត៌មានសម្ងាត់ (Presentation on Confidential Information)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '29536529-ff44-4aea-9ff6-b2235c6c21fb',
    name: "បទបង្ហាញស្តីពីព័ត៌មានសម្ងាត់ និងសិទ្ធិទទួលព័ត៌មាន ដោយសាស្រ្តាចារ្យ   Raymond Leos (Prof. Raymond Leos's Presentation on Confidential Information and A2I)",
    rate: 15.0,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'e426652e-2a95-4196-aa66-5d5bb570782d',
    name: 'បរិញ្ញាបត្រ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '67eb5ca8-e472-4393-8830-4a7eb02c968b',
    name: 'ប៉ាតង់ 2018',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd381c7bc-a0fb-4fa4-bbb2-391a5ee7d087',
    name: 'ប្រកាស',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '32ec4a4e-22d3-427e-8a96-3084840ffd7f',
    name: 'ប្រកាស៣៣៥ ​២១៩៣និងការគ្រប់គ្រងបំណុលពន្ធ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '03baed70-21da-4c49-95f1-312c270966d1',
    name: 'ប្រកាសស្ដីពីការជ្រើសរើសនិស្សិ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '11f83544-988e-460d-a790-6bfd5a8e489a',
    name: 'ប្រកាសស្ដីពីការបង្កើតក្រុមការងារចម្រុះរវាង GDT & CSOs 101 (2019)',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'bff09119-8ef9-46bc-85d4-19d06af8f20d',
    name: 'ប្រកាសស្ដីពីអាកលើការដឹកទំនិញ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '866aa46b-9dab-4c70-a100-86c167591d57',
    name: 'ប្រកាសស្តីពីកាតព្វកិច្ចដាក់របាយការណ៍ហិរញ្ញវត្ថុ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '702f9330-77fc-473e-b943-4c68c7e6d8bd',
    name: 'ប្រតិបត្តិការ​ដោយ​ការ​ទទួល​ខុស​ត្រូវរបស់ធុរកិច្ច (RBC)​  ការ​ពិនិត្យ​ដ៏​ត្រឹម​ត្រូវ Due Diligence (Urgent)',
    rate: 6.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: '67b66bb7-b218-4c20-b123-17b64fdca625',
    name: 'ប្រតិបត្តិការដោយ​ការ​ទទួល​ខុស​ត្រូវ របស់ធុរកិច្ច​​​ (​ Urgent)',
    rate: 6.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'e425a40c-04ad-4133-ae05-04c486158cd9',
    name: 'ប្រតិបត្តិការដោយការទទួលខុសត្រូវរបស់ធុរកិច្ច - ប្រទេសកម្ពុជា',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'def59aeb-1956-47cf-87f9-87dfb5b4fbb1',
    name: 'ប្រតិបត្តិការដោយការទទួលខុសត្រូវរបស់ធុរកិច្ច និងយុទ្ធសាស្រ្តនៃទំនួលខុសត្រូវខាងសង្គមរបស់សាជីវកម្ម ឆ្នាំ២០១៦-២០២១ (Urgent Service)',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '64f85a0f-da25-4c4a-8ebc-5492005e4eb3',
    name: 'ប្រអប់អង្គុយសម្រាប់អ្នកបកប្រែ',
    rate: 15.0,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'f4e3f6a1-a82a-4095-b5ec-64a2d8a9209f',
    name: 'ប្លង់ផ្ទះ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5a4486a6-425d-43a6-936f-d4a87f2661dd',
    name: 'ប័ណ្ណប៉ាតង់',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0b8026ee-fb57-48ea-a7cf-65fe68f95886',
    name: 'ផលប៉ះពាល់នៃការអភិវឌ្ឍន៍បឹងទំពន់',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ded53ecd-1198-497d-92c3-d8943c1aecf5',
    name: 'ផែនការរៀបចំវគ្គសម្រាប់វេទិការសិក្សាលើកទី៥',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6d1143d8-eda2-449c-895a-f216411d88d6',
    name: 'ផែនការសង្គម  - ប្រទេសកម្ពុជា - ឆ្នាំ២០២១',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '27c5d259-17de-4d71-bcf8-b5ca65cdb719',
    name: 'ផែនការសន្សំប្រាក់ចូលនិវត្តន៍អន្តរជាតិ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0c5b3fa6-9b20-4a79-88d0-f0a64fa5d90f',
    name: 'ពាក្យបណ្ដឹងសុំអន្តរាគមន៍',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '27b01e34-1521-46b0-9763-66964912bd2d',
    name: 'ពាក្យសុំចុះបញ្ជីអំពីការបង្កើតហ៊ីប៉ូតែក (2)',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '15296e08-0ef5-4b19-b63c-552f4e117e73',
    name: 'ព្រឹត្តិបត្រថ្កោលទោស',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '67699be0-eaaf-4b55-bbf4-4e75f5c7b128',
    name: 'ព្រឹត្តិប័ត្រអេឡិចត្រូនិច ONE ASIA ឆ្នាំ២០២០ បោះពុម្ពផ្សាយលើកទី៣',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '29f3c278-3a5f-48e9-882f-494a99a3c948',
    name: 'ព្រះរាជក្រឹត្យ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '69533aac-1fbc-4920-bfcb-8a9c38cf1ec3',
    name: 'ព័ត៌មានក្លែងក្លាយនិងផលប៉ះពាល់របស់ព័ត័មានក្លែងក្លាយ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2d70136f-b125-4d78-802e-9de1582d3863',
    name: 'មគ្គុទេសក៍វិនិយោគក្រុងកំពង់ចាម',
    rate: 27.5,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b6fe3348-cfee-404c-ab40-1f417247a5b8',
    name: 'មគ្គុទេសក៍វិនិយោគកំពង់ចាម',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6371cafe-9bda-4647-94ef-ea060147ca7f',
    name: 'មគ្គុទេសក៍វិនិយោគខេត្តកំពង់ចាម',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ca9d6b3a-7d23-4799-bc4c-bd16b3be856f',
    name: 'មគ្គុទេសក៍ស្តីពីការបង្កើតសាខារបស់សមាគម YEAC',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5884a8d2-ca7d-4112-ae5c-cab74b98b7a8',
    name: "មតិយោបល់ និងការវិភាគរបស់ UNESCO ទៅលើជំពូកទី ៤ (UNESCO's Comments and Analysis on Chapter 4);",
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ea72a987-1734-4f68-9658-04fa8aafd04e',
    name: "មតិយោបល់ និងការវិភាគរបស់ UNESCO ទៅលើជំពូកទី ៥&amp;amp;៦ (UNESCO's Comments and Analysis on Chapter 5&amp;amp;6);",
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6533a809-2f08-47e0-abf7-4f933d4e84d6',
    name: "មតិយោបល់ និងការវិភាគរបស់ UNESCO ទៅលើជំពូកទី ៧ (UNESCO's Comments and Analysis on Chapter 7);",
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e849abe7-0b79-4a54-a967-e4a8095ed83a',
    name: 'មតិយោបល់ និងការវិភាគលើជំពូកទី ៤ ស្តីពីព័ត៌មានសម្ងាត់ (Comments and Analysis on Chapter 4 on Confidential Information)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b422d10d-1e1f-4da0-95f7-10ba8750318c',
    name: 'មតិយោបល់បានមកពី UNESCO OHCHR និង CSOs (Comment on Articles 16-19)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8485ac98-5d59-4b82-93eb-9a358ad476c2',
    name: 'ម៊ូដល ៤ ការជួយខ្លួនឯង',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6bb39275-ba17-4c69-a0a8-e6ca2ff42e44',
    name: 'មាត្រា ២៣ ថ្មី (Article 23 New);',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '93419d92-5b7e-4945-ac02-11a987e1fa37',
    name: 'មាត្រា១៦-១៩ (Articles 16-19)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '89915c28-ea00-4846-a41a-f633f08991e9',
    name: 'មុខម្ហូប (ភាសាអង់គ្លេសទៅចិន)',
    rate: 25.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'db6fe6d1-d2a5-4c8d-af76-7169e374cbca',
    name: 'មេរៀនសិក្សា',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '04b4053a-bc9c-45f3-ae1a-3f4864e7e1bc',
    name: 'យុទ្ធសាស្រ្ត​តស៊ូមតិ​សម្រាប់ ប្រតិបត្តិការដោយ​ការ​ទទួល​ខុស​ត្រូវរបស់ធុរកិច្ច(urgent)',
    rate: 6.5,
    unitTypeId: '19d7ccdb-26b0-489f-8661-d613d96d6653',
  },
  {
    id: 'c891597c-d912-468a-9a1d-2d3e0c607d48',
    name: 'របាយការណ៍កិច្ចប្រជុំ ad-hoc ចំនួនពីរ (Two Ad-hoc Meetng Reports',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '39e69a4d-5612-4f20-bf20-27c55cc2c66a',
    name: 'របាយការណ៍កិច្ចប្រជុំដាក់ជួនរដ្ឋមន្រ្តី (Minute Report to Minister)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '082064a8-0e1b-4170-a7fc-33b2e9a3dc47',
    name: 'របាយការណ៍កិច្ចប្រជុំបញ្ចប់សេចក្តីព្រាងច្បាប់ A2I (Completion of A2I Draft Law Report',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '44b1172e-3e11-4427-8e39-c31d5227a79c',
    name: 'របាយការណ៍កិច្ចប្រជុំពិនិត្យមើលឡើងវិញលើសេចក្តីព្រាងច្បាប់ A2I (A2I Draft Law Review Meeting Report) ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '88c98723-01f3-42e4-933a-27af3bd69163',
    name: 'របាយការណ៍កិច្ចប្រជុំរបស់អនុគណៈកម្មការ (Reports on the Sub-Committee’s Meetings)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6ac9529b-4197-41c3-a19c-bf989b7bb147',
    name: "របាយការណ៍កិច្ចប្រជុំអនុគណៈកម្មការ (Report on the Sub-committee's Meeting) 17/07/2017",
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '18cc0d35-5ecd-43d1-a37f-8eaacc23dedc',
    name: "របាយការណ៍កិច្ចប្រជុំអនុគណៈកម្មការ (Report on the Sub-committee's Meeting) 20/06/2017",
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fe370cfa-649a-427a-a113-ffb762864434',
    name: "របាយការណ៍កិច្ចប្រជុំអនុគណៈកម្មការ (Report on the Sub-committee's Meeting) 21/07/2017",
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4fcc18e0-15e8-4f01-8107-4ba10bf7fd59',
    name: 'របាយការណ៍ប្រចាំឆ្នាំ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4de61fbb-259f-4c26-9d43-e19794f285f9',
    name: 'របាយការណ៍ផ្លូវច្បាប់ស្ដីពីការផ្ដល់សេវាដើម្បីទិញដី ខ្មែរ-អង់គ្លេស',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '83e1926a-2281-4939-97fd-ca3bab4e2b1b',
    name: 'របាយការណ៍វាយតម្លៃការងារបានបញ្ចប់',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '30b95e28-bc15-432b-bca0-85709973e547',
    name: 'របាយការណ៍ស្តីពី ការបង្កើតផែនទី ស៊ីអេសអូ នៅកម្ពុជា',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '109ddc37-ba87-434c-9abf-1f26abb7afb0',
    name: 'របាយការណ៍ស្តីពីកិច្ចប្រជុំលើកទី ១១ គណៈកម្មការ (Report on the 11th meeting of the committee)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd457e2f5-4ce0-4267-a02a-38eb8ded854f',
    name: 'របាយការណ៍ស្តីពីកិច្ចប្រជុំលើកទី ១២ របស់គណៈកម្មការ (Report on the 12th meeting of the committee)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'e9762bd3-6202-49b4-87b6-2d3bb7e14530',
    name: 'របាយការណ៍ស្តីពីកិច្ចប្រជុំលើកទី ១៤ របស់អនុគណៈកម្មការ (Report on the 14th meeting of the sub-committee)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '79c18343-aa09-444d-93ce-03ddc0172295',
    name: 'របាយការណ៍ស្តីពីវគ្គបណ្តុះបណ្តាល "ព័ត៌មានសម្ងាត់" (Report on a training course on "Confidential Information")',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '587c44f2-75dd-4e0a-8b12-6fc026a5eab0',
    name: 'របាយការណ៍សំយោគ_ការអនុវត្តន៍',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd0904089-a6b3-4dba-b94f-2e8a8711fec4',
    name: 'របាយការណ៍សំយោគ_ចូលរួមដោយរដ្ឋាភិបាល',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a3ceaf82-3ba5-4ba3-9a51-ff9e512b6a9f',
    name: 'របាយការណ៍សំយោគ_វិស័យឯកជន',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '713f90cb-f946-4eca-ab41-dc08fb555f81',
    name: 'របាយការណ៏ឆ្នាំ២០១៦',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '2b579edc-5ca7-4578-8df9-669906b9d2e8',
    name: 'លក្ខខណ្ឌសំអាង',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '9cb3dac5-fdfa-4300-8997-acc9bb775d52',
    name: 'លក្ខន្តិកៈ​ក្រុមហ៊ុន',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'bac882e5-a69f-40fe-89d7-f67c9f592e39',
    name: 'លក្ខន្តិកៈក្រុមហ៊ុន',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b8eb488e-cf44-42a0-b6bf-f6f4826149f3',
    name: 'លិខិតកាន់កាប់ដីធ្លី',
    rate: 7.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f8971976-9329-41ce-88dc-9193df0f9270',
    name: 'លិខិតគោរពជូន',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a967b00f-0747-445a-8d43-62431fcab931',
    name: 'លិខិតឆ្លើយតប (លើកទី២) ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6b5ada24-7009-4162-a91f-8ceba49ca489',
    name: 'លិខិតជម្រាបជូន',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a611c8ff-e135-43af-aed9-72420cb8a009',
    name: 'លិខិតជូនដំណឹង (ខ្មែរ-អង់គ្លេស)',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6fe40706-c7e9-41c9-bc00-bdae58d44a25',
    name: 'លិខិតជូនដំណឹង (អង់គ្លេស-ចិន)',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ac0678e9-219b-40bd-90ba-3b3dddb6111a',
    name: 'លិខិតទទួលប្រាក់កក់ឈ្នួលជាមុន',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '82c676d8-8686-48cb-86b6-404e1c2d8c2d',
    name: 'លិខិតធានា',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '009a6b7b-b300-47ff-b7c3-0e7b3bb6f70b',
    name: 'លិខិតបញ្ជាក់',
    rate: 7.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3c6ea977-ab94-4114-9ded-40c367a9ecdc',
    name: 'លិខិតបញ្ជាក់ការងារ',
    rate: 7.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '367b65c3-4ca7-462d-bd23-cae0e1cfdde3',
    name: 'លិខិតបញ្ជាក់ពីក្រុមហ៊ុនក្នុងការជួលរថយន្ត',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c56756bc-8667-4d90-b0d6-70b8c566ecf3',
    name: 'លិខិតបញ្ជាក់សុវត្ថិភាពជម្ងឺឆ្លងរបស់សត្វ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '416cf737-bf48-4a07-8f47-e940553259b9',
    name: 'លិខិតប្រគល់ទទួលយករថយន្ត',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5fd1cf1f-22fc-4572-870a-cfbf26eae685',
    name: 'លិខិតប្រគល់សិទ្ធិ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c3edb77f-eaab-40f3-b077-1fed4c56778e',
    name: 'លិខិតពីក្រសួងអន្តោរប្រវេសន៍',
    rate: 12.5,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '50a2eb83-7b00-434b-8697-21f04d371b8a',
    name: 'លិខិតពីអគ្គនាយកដ្ឋានពន្ធដារ',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7b1b10fb-6546-438a-a871-56745fc6a90a',
    name: 'លិខិតរបស់អគ្គនាយកដ្ឋានពន្ធដារ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ab583e8c-b47e-4e57-93c8-c99b97d32b4b',
    name: 'លិខិតលែងលះ',
    rate: 9.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '23ce64de-2358-4e6d-8665-9e2d39299a2a',
    name: 'លិខិតស្នើសុំជួប',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '91ef7dd4-468a-4959-be45-d2428421d56d',
    name: 'លិខិតអញ្ជើញ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fd9171f0-a470-4f8e-b391-6d009644f4ee',
    name: 'លិខិតអនុញ្ញាត្តសាងសង់',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'cb9965f7-5aa2-4af3-8826-1dcb15542e34',
    name: 'លិខិតអបអរសាទរ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '9a52a16a-a3c6-4c89-a2ed-dffe97a0595a',
    name: 'លិខិប្រគល់សិទ្ធិតំណាងដោយអាណត្តិ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '19466254-fca2-462b-867d-50ec851f6b1c',
    name: 'លើកលែងពន្ធចំពោះប្រាក់បំណាច់អតីតភាព',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ab3dc117-d7a9-4a10-aa82-f1db5254d3c4',
    name: 'វិក្កយបត្រទឹក',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '205fef6b-30eb-4c1c-b827-cab7513af7f9',
    name: 'វិញ្ញាបនបត្រ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'cb03f6a7-84ff-4873-8cfd-9c7665a87cee',
    name: 'វិញ្ញាបនបត្រចុះបញ្ជីពន្',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f04ffc0d-e710-4a4d-ab1f-febfcbf3cc54',
    name: 'វិញ្ញាបនបត្របណ្ដោះអាសន្នថ្នាក់បរិញ្ញាបត្ររង',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8403dcb1-0202-4ed0-8466-68c939173954',
    name: 'វិញ្ញាបនបត្រពាណិជ្ជកម្ម',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd857549d-da12-4490-a169-05b8b7cc5356',
    name: 'វិញ្ញាបនបត្រសម្គាល់ម្ចាស់អចលនវត្ថុ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7d191f13-93a3-4e64-9105-d56ca57112a0',
    name: 'វិញ្ញាបនបត្រអាជ្ញាប័ណ្ណអភិវឌ្ឍន៍លំនៅដ្ឋានប្រភេទ២',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4af5103c-a1fe-4076-bb0a-3a7b6e3ae70f',
    name: 'សម្រង់សំណួរ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '94d7f4b2-0371-4d69-bfa7-7c29b5bc8262',
    name: 'សាររបស់ហុងកុងលែនអំពីសហគមន៍ និងទីក្រុងប្រកបដោយចីរភាព',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c71013e7-083a-4514-b827-2cb0081f07f6',
    name: 'សារាចរណែនាំស្ដីពីការពន្យារពេលអនុវត្តការពិន័យ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '64edc2df-b2ba-415a-80af-309f297abbd8',
    name: 'សាលក្រម',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '03e52be4-f5a7-4f5d-9326-c4035d168207',
    name: 'សាវតា ប្រវត្តិ និងមេរៀនរបស់កម្មវិធី Do No Harm',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '27a80fdb-20a2-4a5c-9d9f-91dabb21eb5a',
    name: 'សៀវភៅគ្រួសារ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '01f09b63-dd8e-4318-af1f-3f0cc326ec2b',
    name: 'សៀវភៅណែនាំសម្រាប់គ្រូបង្គោល  យេនឌ័រ និងភាពធន់នឹងការប្រែប្រួលអាកាសធាតុ',
    rate: 9.09,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd736ae97-6aca-401a-924d-38869e3b8e82',
    name: 'សៀវភៅស្នាក់នៅ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6d1cbb22-157f-45e4-bacf-9f341014d421',
    name: 'សេចក្ដីជូនដំណឹង',
    rate: 10.91,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '80682a71-0927-4109-b371-f415a34e63f7',
    name: 'សេចក្ដីណែនាំស្ដីពីការបើកប្រាក់រំលឹកអតីតភាពការងារ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'db132654-d0c6-47c8-a1cc-0337da90d0f1',
    name: 'សេចក្ដីថ្លែងការ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '904641ec-7402-4a44-b1af-ff6d81d54435',
    name: 'សេចក្ដីថ្លែងការណ៍រួមក្រុងបាងកក',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '51e651fb-7657-4582-9d5b-053f91a9bb67',
    name: 'សេចក្ដីបញ្ជាក់ស្ដីពីការបានបំពេញនូវកាតព្វកិច្ចចំពោះរដ្ឋ សម្រាប់ឆ្នាំ២០១៨ (ខ្មែរ-ចិន)',
    rate: 12.5,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a57194a9-f02c-40ce-859e-afdf1821bd35',
    name: 'សេចក្ដីបញ្ជាក់ស្ដីពីការបានបំពេញនូវកាតព្វកិច្ចចំពោះរដ្ឋ សម្រាប់ឆ្នាំ២០១៨ (ខ្មែរ-អង់គ្លេស)',
    rate: 12.5,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '9cd2cde6-d5a8-4690-bab2-344005134237',
    name: 'សេចក្ដីរាយការណ៍ និងការស្ទង់មតិ ច្បាប់ស្តីពីសមាគមន៍ និងអង្គការមិនមែនរដ្ឋាភិបាល',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '245ae50e-6fa2-4922-beb8-f1b8090b2384',
    name: 'សេចក្ដីសម្រេចតម្កល់ដីកាតម្កល់រឿងទុកឥតចាត់ការ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ff7e48c0-c75c-4d0a-89c1-2906f0dc3a9f',
    name: 'សេចក្ដីសម្រេចរបស់សាខាពន្ធដាខេត្ដសៀមរាប',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fe909fdb-5185-4b20-bfce-66216b137784',
    name: 'សេចក្តីណែនាំស្ដីពីការលើកទឹកចិត្តផ្នែកពន្ធដារចំពោះ អង្គការ សមាគម',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '34c6d6aa-e74a-44d6-ac76-c8ab941cf193',
    name: 'សេចក្តីថ្លែងការណ៍ស្តីពីលទ្ធផល កិច្ចប្រជុំកំពូលប្រចាំឆ្នាំ ២០១៧ ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3c3dcaa0-af52-490c-a8a9-461133a9036b',
    name: 'សេចក្តីថ្លែងការណ៍អំពីលទ្ធផលកិច្ចប្រជុំប្រចាំឆ្នាំ២០១៦ របស់គណៈកម្មាធិការ គ.ស.ក',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8c46e94c-cc02-4512-b791-47d179348b78',
    name: 'សេចក្តីបង្គាប់ស្ថាពរ មជ្ឈមណ្ឌលជាតិនៃមជ្ឈត្តការផ្នែកពាណិជ្ជកម្ម (NCAC’s final award)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd9d64714-84a7-464c-9900-49d585ffe1df',
    name: 'សេចក្តីព្រាងច្បាប់បឋមស្តីពី A2I (Prelimiary Draft Law on A2I) ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'c41ea168-9eb2-4c47-af91-6e3dd6fdff0e',
    name: 'សេវាបកប្រែផ្ទាល់មាត់',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '295ee4f4-a966-4be5-87f6-807cee345e24',
    name: 'ស្តង់ (Booth)',
    rate: 20.0,
    unitTypeId: '7d5b7e00-5443-4b24-9ae8-d4c8092be1bc',
  },
  {
    id: 'b03506f5-be01-41f4-ad08-81b86be944bb',
    name: 'ស្តង់សម្រាប់អ្នកបកប្រែ',
    rate: 20.0,
    unitTypeId: '7d5b7e00-5443-4b24-9ae8-d4c8092be1bc',
  },
  {
    id: '5c0377a4-a2af-4ec1-90f6-c1edd4050a66',
    name: 'ស្តីពីការលើកលែងពន្ធលើប្រាក់ចំណូល',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8b581fe4-fc34-4adb-beb8-d6a3522e114a',
    name: 'ស្តីពីការអនុវត្តអាករលើតម្លៃបន្ថែមលើការផ្គត់ផ្គង់ផលិតផលកសិកម្មមិនទាន់កែច្នែ VAT exemption on agriculture products',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '944ddf38-fbc3-49ab-a828-97badc0fff0d',
    name: 'ស្នើសុំបញ្ចូនសំណុំរឿងបរិហាកេរ្តិ៍',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b20fd55b-d9a7-4157-9c10-d560a19ec436',
    name: 'ស្នើសុំបញ្ចូនសំណុំរឿងលេខ ០៧២ ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '7dff5721-6205-4731-bd51-81e8937867f1',
    name: 'សំណើរសុំនាំចូលនូវគ្រឿងម៉ាស៊ីន(ខ្មែរ-ចិន)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8101a430-9b6c-4e11-9cdb-5ff39e7e31be',
    name: 'សំណើរសុំនាំចូលនូវគ្រឿងម៉ាស៊ីន(ខ្មែរ-អង់គ្លេស)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '29ebc93c-6739-4cf7-83db-b597d8bfe486',
    name: 'សំណើរសុំលើកលែងពន្ធលើប្រាក់ចំណូល',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1471a51a-e0f3-472f-951c-925840e15790',
    name: 'សំណើសុំកែប្រែលើជំពូកទី ៤ (ជម្រើសទី ១) (Proposition of Amendments to Chapter 4 (1st Option))',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6ca133de-d01b-40e5-9a39-cef51959bd6f',
    name: 'សំណើសុំកែប្រែលើជំពូកទី ៤ (ជម្រើសទី ២) (Proposition of Amendments to Chapter 4 (2nd Option))',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8b1dc230-cc02-40db-a205-f72a1d865ba1',
    name: 'សំណើសុំជួបសម្ដែងការគួរសេមដើម្បីសុំអនុសាសន៍',
    rate: 13.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f5db1c23-4955-4f30-9811-76c9921b008f',
    name: 'សំណើសុំធ្វើការបកស្រាយ ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd414f0ce-87c1-4272-a3d8-0ceb418a2385',
    name: 'សំណើសុំផ្តល់សេវាសវនកម្មដល់ក្រុមហ៊ុន ដាយ អ៊ិឈិ ឡាយហ្វ៍ អ៊ីនសួរ៉ិនស៍ (ខេមបូឌា) ម.ក',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '68261611-4eda-403f-a3ee-f76b30c17b94',
    name: 'សំបុត្រកំណើត',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0fe30d08-1c24-48cb-8a05-d445cc669fec',
    name: 'សំបុត្របញ្ជាក់កំណើត',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '596a82ae-99d8-4904-824d-c8b7c910a8d3',
    name: 'សំបុត្រមរណភាព',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'da1559eb-8eb2-431b-bef2-d92d7ec61323',
    name: 'សំបុត្រអាពាហ៍ពិពាហ៍',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '28a6a94c-ffe0-404c-a331-bd6e0e957b28',
    name: 'ហិរញ្ញវត្ថុ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '1c74f575-cce5-4e60-82d3-437128e9f0d2',
    name: 'ឯកសារ (ខ្មែរ-វៀតណាម)',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '695c8c5c-1d05-47b2-9cf5-190da32f70a5',
    name: 'ឯកសារ CDC (ខ្មែរ-ចិន)',
    rate: 16.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'de5df5df-b7f0-446d-a226-26ebf436d043',
    name: 'ឯកសារ CDC (ខ្មែរ-អង់គ្លេស)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5ddfbce1-372e-4b49-ba1a-f0f5de499aa3',
    name: 'ឯកសារ សីលធម៌ និងសុចរិតភាព ក្រមសីលធម៌ ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '4e017a60-ad49-43c9-b71c-c37da9fe6f09',
    name: 'ឯកសារ​ បញ្ជីឈ្មោះសមាជិក',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8c80ca80-8075-4738-b3d6-ebd6435d7894',
    name: 'ឯកសារ​ សារឆ្លើយឆ្លង',
    rate: 5.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8e97a5cb-38ac-4809-a0f3-aef9219c5d86',
    name: 'ឯកសារកុងត្រា (Contract English to Khmer)',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '49c84c98-eb69-4dfa-abde-631d8cbcd653',
    name: 'ឯកសារកុងត្រា (ខ្មែរ- ចិន)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '293628ae-18c2-4f8d-98df-ff6862907faa',
    name: 'ឯកសារកុងត្រា (ចិន-អង់គ្លេស)',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ea6e7783-6f70-4223-8c8a-cc06917d0766',
    name: 'ឯកសារក្រុមហ៊ុន ខ្មែរ អង់គ្លេស',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'fe9995bc-ed6b-486c-8d6f-710f41106ead',
    name: 'ឯកសារចុះបញ្ជីពន្ធដារ',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ee14e07a-d9ea-473c-b5a0-ff9e79e56397',
    name: 'ឯកសារត្រៀមសម្រាប់ប្រជុំជាមួយ UN (Memo for meeting with UN)',
    rate: 15.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '0e66f8a2-e052-46be-8f88-6e2c1636b644',
    name: 'ឯកសារថៃ ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '42c7acad-33fc-492b-920c-3a86aff73d75',
    name: 'ឯកសារថៃ (ថៃ-ខ្មែរ)',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a9d08d94-4113-4a60-8062-389b0e0002ce',
    name: 'ឯកសារទស្សនទាន ព្រឹត្តិការណ៍បើកសម្ពោធគម្រោង',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f1cff95c-57fd-44f7-b07e-3d910e0fb114',
    name: 'ឯកសារមកពីក្រសួងសាធារណការ និងដឹកជញ្ជូន',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '5dfb7241-3afd-4d8d-be1c-25ccef5ba7a3',
    name: 'ឯកសាររដ្ឋបាល',
    rate: 8.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '3b233324-7a49-47ec-8b58-e8227cea645d',
    name: 'ឯកសាររ៉ែ',
    rate: 18.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'd3aab54a-cd1f-40a8-b9c8-17e19e73f363',
    name: 'អត្តសញ្ញាណប័ណ្ណ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8babe9ac-6a7e-407d-b33a-514e8f6e90c9',
    name: 'អត្តសញ្ញាណប័ណ្ណ (ចិន)',
    rate: 7.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '728507d0-23ca-4b54-80f2-90a93ec9f856',
    name: 'អត្តសញ្ញាណប័ណ្ណ ចិន',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'a2a2de2d-94d9-4705-9173-a4ea0fdb7cb1',
    name: 'អត្តសញ្ញាណប័ណ្ណខ្មែរ',
    rate: 6.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '67b741fe-8642-4535-9800-2f7bae787dc1',
    name: 'អនុក្រិត្យ ស្ដីពី ការលើកទឹកចិត្តផ្នែកពន្ធដារ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '8fc2ac1f-a9c5-41a1-a0c7-61509a926d81',
    name: 'អនុក្រឹត្យ ស្ដីពី ការគ្រប់គ្រង​អាងទន្លេ',
    rate: 12.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '61d0cefd-a7d2-4330-b93e-2df1b8b326c6',
    name: 'អនុស្សរណៈនៃការយោគយល់ ស្តីពី ភាពជាដៃគូសម្រាប់អង្គការមិនមែនរដ្ឋាភិបាល ',
    rate: 11.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'bc86d42e-bd8d-4336-a313-8ac08122d97b',
    name: 'អនុស្សារណៈ',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'dee18140-5dbd-4d19-bc3c-b0c6609df6c4',
    name: 'អនុស្សារណៈ (១១ តុលា ឆ្នាំ២០១៦)',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'ddfafa45-09c5-417e-86e2-be86aaa9c534',
    name: 'អនុស្សារណៈ (២៣ កុម្ភៈ ឆ្នាំ២០១៧)',
    rate: 10.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'b3f86895-71a9-4356-9c89-0da0dedfdc6a',
    name: 'ឧបសម្ព័ន្ធ ១៣',
    rate: 20.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: '6627117d-4fe0-491d-8c21-cdf0f7a8e3b3',
    name: 'អាជ្ញាប័ណ្ណសេវាកម្ម',
    rate: 14.0,
    unitTypeId: '67cd2370-52c7-4aef-ba79-425d69396b01',
  },
  {
    id: 'f6f2a8aa-0939-4a02-a3a0-03181d8107f3',
    name: 'អ្នកបកប្រែទី 1',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
  {
    id: '028586a2-16b1-4d9e-bcbb-e3c80361f999',
    name: 'អ្នកបកប្រែទី 2',
    rate: 250.0,
    unitTypeId: '56814a1a-a972-4965-955c-d10316a14a7e',
  },
]
