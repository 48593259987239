import mock from '../mock'
let i = 0
mock.onPost('/app/email/send').reply(() => {
  i++
  if (i % 2 === 0) {
    return [
      400,
      {
        success: false,
        error: {
          model: {
            fromEmail: ['invalid email'],
          },
        },
      },
    ]
  }
  return [200, { success: true }]
})
