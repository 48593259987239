import mock from '../../../@fake-db/mock'
import general from './general'
import company from './company'
import social from './social'
import notification from './notification'

const data = {
  general,
  company,
  social,
  notification,
}

mock.onGet('/app/setting/account').reply(() => [
  200,
  {
    success: true,
    data,
  },
])

mock.onGet('/app/setting/account/recent-devices').reply(() => [
  200,
  {
    success: true,
    data: [
      {
        device: 'Dell XPS 15',
        location: 'United States',
        browser: 'Chrome on Windows',
        activity: '10, Jan 2021 20:07',
      },
      {
        location: 'Ghana',
        device: 'Google Pixel 3a',
        browser: 'Chrome on Android',
        activity: '11, Jan 2021 10:16',
      },
      {
        location: 'Mayotte',
        device: 'Apple iMac',
        browser: 'Chrome on MacOS',
        activity: '11, Jan 2021 12:10',
      },
      {
        location: 'Mauritania',
        device: 'Apple iPhone XR',
        browser: 'Chrome on iPhone',
        activity: '12, Jan 2021 8:29',
      },
    ],
  },
])

mock.onPut('/app/setting/account').reply(config => {
  const newData = JSON.parse(config.data)
  if (newData.company) {
    if (!newData.company.firstName || newData.company.firstName.trim().length === 0) {
      return [
        400,
        {
          success: false,
          error: {
            model: {
              firstName: ['Please enter first name'],
              _summary: ['Please enter name'],
            },
          },
        },
      ]
    }
  }
  if (newData.general) {
    data.general = { ...data.general, ...newData.general }
  }
  if (newData.company) {
    data.company = { ...data.company, ...newData.company }
  }

  if (newData.notification) {
    data.notification = { ...data.notification, ...newData.notification }
  }

  return [
    200,
    {
      success: true,
      data,
    },
  ]
})

mock.onDelete('/app/setting/account').reply(() => {
  data.accountSetting = null
  return [
    200,
    {
      success: true,
      data,
    },
  ]
})
