// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from '@api'

export const getAllData = createAsyncThunk('appClients/getAllData', async () => {
  const response = await api.get('app/clients/all')
  return response.data.data.items
})

export const getData = createAsyncThunk('appClients/getData', async params => {
  const response = await api.get('app/clients', params)

  return {
    params,
    data: response.data.data.items,
    totalPages: response.data.data.total,
  }
})

export const getClient = createAsyncThunk('appClients/getClient', async id => {
  const response = await api.get(`app/clients/${id}`)
  return response.data.data
})

export const addClient = createAsyncThunk('appClients/addClient', async (client, { dispatch, getState }) => {
  await api.post('/app/clients', client)
  await dispatch(getData(getState().clients.params))
  await dispatch(getAllData())
  return client
})

export const updateClient = createAsyncThunk('appClients/updateClient', async (client, { dispatch, getState }) => {
  const response = await api.put(`/app/clients/${client.id}`, client)
  await dispatch(getData(getState().clients.params))
  await dispatch(getAllData())

  return response.data.data.client
})

export const deleteClient = createAsyncThunk('appClients/deleteClient', async (id, { dispatch, getState }) => {
  await api.delete(`/app/clients/${id}`)

  await dispatch(getData(getState().clients.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appClients',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null,
  },
  reducers: {
    editClient: (state, action) => {
      state.selected = {
        ...action.payload,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.selected = action.payload
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  },
})

export const { editClient } = appClientsSlice.actions

export default appClientsSlice.reducer
