// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { createRequest } from '../api'

export const getConfigs = createAsyncThunk('config/getConfigs', async () => {
  const data = await createRequest('app/configuration/global')[0]

  return {
    data,
  }
})

export const configSlice = createSlice({
  name: 'session',
  initialState: {
    user: {},
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.currentUser
    },
  },
})

export const { setUser } = configSlice.actions

export default configSlice.reducer
