import mock from '@src/@fake-db/mock'

// ** Utils
import { paginateArray } from '../../../@fake-db/utils'

const data = {
  items: [
    {
      id: 4987,
      issuedDate: '2022-02-07T11:38:16.006Z',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        countryCode: 'USA',
        contact: '(616) 865-4180',
        name: 'Jordan Stevenson',
      },
      attachments: [
        {
          fileName: 'E0100FEUOH.pdf',
          url: 'http://localhost:8080/api/v1/parse/files/iqTHmXnkOodDRNL3lQVeuObugmXbDkBzdiMw6QCT/9243083cd3176d11fe6b61164eb2a8f1_E0100FEUOH.pdf',
        },
      ],
      service: 'Software Development',
      subTotal: 44,
      total: 3428,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 724,
      dueDate: '2022-03-07T11:38:16.006Z',
      discount: {
        amount: null,
        value: null,
        type: 'percentage',
        unit: '%',
      },
      taxes: [
        {
          amount: -12.72,
          name: '12',
          rate: 12,
        },
      ],
      dueTotal: 0,
      exchangeRate: 0,
      items: [
        {
          id: 1,
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 20000,
          unitType: {
            code: 'hour',
            label: 'ម៉ោង',
          },
          discount: {
            value: null,
            amount: null,
            type: 'percentage',
          },
        },
        {
          id: 2,
          name: 'Translation text',
          qty: 10,
          rate: 30,
          total: 3000,
          unitType: {
            code: 'hour',
            label: 'ម៉ោង',
          },
          discount: {
            value: null,
            amount: null,
            type: 'percentage',
          },
        },
      ],
      currency: 'KHR',
      activities: [
        {
          type: 'Invoice',
          description: 'បានកែប្រែ trt3.',
        },
      ],
    },
    {
      id: 4988,
      currency: 'USD',
      issuedDate: '2022-02-07T11:38:16.006Z',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'Mccann LLC and Sons',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'Stephanie Burns',
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      avatar: require('@src/assets/images/avatars/10-small.png').default,
      invoiceStatus: 'Downloaded',
      balance: 0,
      dueDate: '2022-03-07T11:38:16.006Z',
      items: [
        {
          id: 1,
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 300,
          discount: {
            value: null,
            amount: null,
            type: 'percentage',
          },
        },
        {
          id: 2,
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 300,
          discount: {
            type: '%',
            amount: 10,
          },
        },
      ],
    },
    {
      id: 59030,
      issuedDate: '2022-02-07T11:38:16.006Z',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'USA',
        contact: '(616) 865-4180',
        name: 'Jordan Stevenson',
      },
      service: 'Software Development',
      total: 3428,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: '$724',
      dueDate: '2022-03-07T11:38:16.006Z',
      items: [
        {
          id: 1,
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 20000,
          discount: {
            value: null,
            amount: null,
            type: 'percentage',
          },
        },
        {
          id: 2,
          name: 'Translation text',
          qty: 10,
          rate: 100,
          total: 1000,
          discount: {
            value: null,
            amount: null,
            type: 'percentage',
          },
        },
      ],
      currency: 'KHR',
    },
    {
      id: 5900,
      currency: 'USD',
      issuedDate: '2022-02-07T11:38:16.006Z',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'Mccann LLC and Sons',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'Stephanie Burns',
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      avatar: require('@src/assets/images/avatars/10-small.png').default,
      invoiceStatus: 'Downloaded',
      balance: 0,
      dueDate: '2022-03-07T11:38:16.006Z',
      items: [
        {
          id: 1,
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 300,
          discount: {
            value: null,
            amount: null,
            type: 'percentage',
          },
        },
        {
          id: 2,
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 300,
          discount: {
            type: '%',
            amount: 10,
          },
        },
      ],
    },
  ],
}

data.items.forEach(x => {
  x.number = x.id
})

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/app/payments').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.items.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter(invoice => {
    if (String('paid').includes(queryLowered) && invoice.balance === 0) {
      return invoice.balance === 0
    } else {
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      return (
        (invoice.client.companyEmail.toLowerCase().includes(queryLowered) ||
          invoice.client.name.toLowerCase().includes(queryLowered) ||
          String(invoice.id).toLowerCase().includes(queryLowered) ||
          String(invoice.total).toLowerCase().includes(queryLowered) ||
          String(invoice.balance).toLowerCase().includes(queryLowered) ||
          invoice.dueDate.toLowerCase().includes(queryLowered)) &&
        invoice.invoiceStatus.toLowerCase() === (status.toLowerCase() || invoice.invoiceStatus.toLowerCase())
      )
    }
  })
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.items,
      total: filteredData.length,
      items: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page),
    },
  ]
})

mock.onPut(new RegExp('/app/payments/*')).reply(config => {
  const invoiceId = config.url.split('/').at(-1)
  // Get event id from URL

  const invoiceIndex = data.items.findIndex(t => t.id.toString() === invoiceId)

  data.items[invoiceIndex] = JSON.parse(config.data)
  console.log(invoiceIndex, data.items[invoiceIndex])
  const responseData = {
    success: true,
    data: data.items[invoiceIndex],
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete(new RegExp('/app/payments/*')).reply(config => {
  // Get invoice id from URL
  const invoiceId = config.url.split('/').at(-1)

  const invoiceIndex = data.items.findIndex(t => t.id.toString() === invoiceId)
  data.items.splice(invoiceIndex, 1)

  return [200]
})

mock.onGet(new RegExp('/app/payments/*')).reply(config => {
  // Get invoice id from URL
  const invoiceId = config.url.split('/').at(-1)

  const invoice = data.items.find(t => t.id.toString() === invoiceId)

  return [
    200,
    {
      success: true,
      data: invoice,
    },
  ]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/app/payments/clients').reply(() => {
  const clients = data.items.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
