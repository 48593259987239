import mock from '../../../@fake-db/mock'

// ** Utils
import { paginateArray } from '../../../@fake-db/utils'
import { items } from './items'
const data = {
  items,
}

// POST: Add new user
mock.onPost('/app/items').reply(config => {
  // Get event from post data

  const item = JSON.parse(config.data)

  item.id = uuidv4()

  item.createdOn = new Date().toISOString()
  data.items.push(item)

  return [201, { customer: item }]
})

mock.onGet('/app/items').reply(config => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'name' } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.items.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(item => item.name.toLowerCase().includes(queryLowered))
  /* eslint-enable  */

  return [
    200,
    {
      success: true,
      data: {
        total: filteredData.length,
        items: paginateArray(filteredData, perPage, page),
      },
    },
  ]
})

mock.onGet(new RegExp('/app/items/*')).reply(config => {
  const id = config.url.split('/').at(-1)

  const customer = data.items.find(i => i.id === id)
  return [200, { success: true, data: customer }]
})

// DELETE: Deletes User
mock.onDelete(new RegExp('/app/items/*')).reply(config => {
  // Get user id from URL
  const id = config.url.split('/').at(-1)
  const userIndex = data.items.findIndex(t => t.id === id)

  data.items.splice(userIndex, 1)

  return [200]
})

mock.onPut(new RegExp('/app/items/*')).reply(config => {
  const id = config.url.split('/').at(-1)
  const userIndex = data.items.findIndex(t => t.id === id)
  const item = JSON.parse(config.data)

  data.items[userIndex] = item

  return [
    200,
    {
      success: true,
      data: item,
    },
  ]
})
