import mock from '../../../@fake-db/mock'

// ** Utils
import { paginateArray } from '../../../@fake-db/utils'
import { uuidv4 } from '../../../utility/Utils'
const data = {
  items: [
    {
      id: '2a7118ee-8524-477c-92ce-c7492433012d',
      displayName: 'Sadie Rydeard',
      primaryContactName: 'David john',
      company: 'Yotz PVT LTD',
      phone: '(479) 232-9151',
      mobile: '(479) 232-9151',
      email: 'bkisting4@aboutads.info',
      avatar: '',
      taxCode: '100063438',
      website: 'https://redux-toolkit.js.org/api/createAsyncThunk',
      address: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      billingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      shippingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      defaultCurrency: 'usd',
      createdOn: '2022-02-03T10:43:47.467Z',
    },
    {
      id: '2a7118ee-8524-477c-92ce-c74924330123',
      displayName: 'Galen Slixby',
      primaryContactName: 'David john',
      company: 'Yotz PVT LTD',
      phone: '(479) 232-9151',
      mobile: '(479) 232-9151',
      email: 'klambdean7@bing.com',
      avatar: '',
      taxCode: '100063438',
      website: 'https://msn.com',
      address: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      billingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      shippingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      defaultCurrency: 'usd',
      createdOn: '2022-02-03T10:43:47.467Z',
    },
    {
      id: '2a7118ee-8524-477c-92ce-c74924330122',
      displayName: 'Queenie Dincey',
      primaryContactName: 'Melessa Gilliard',
      company: 'Yotz PVT LTD',
      phone: '(479) 232-9151',
      mobile: '(479) 232-9151',
      email: 'srydeard0@opera.com',
      avatar: '',
      taxCode: '100063438',
      website: 'https://godaddy.com/',
      address: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      billingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      shippingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      defaultCurrency: 'usd',
      createdOn: '2022-02-03T10:43:47.467Z',
    },
    {
      id: '2a7118ee-8524-477c-92ce-c74924330121',
      displayName: 'Cherie Wodeland',
      primaryContactName: 'Kaela Lambdean',
      company: 'Yotz PVT LTD',
      phone: '(479) 232-9151',
      mobile: '(479) 232-9151',
      email: 'mgilliard8@icio.us',
      avatar: '',
      taxCode: '100063438',
      website: 'https://barnesandnoble.com',
      address: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      billingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      shippingAddress: {
        fullAddress: 'ក្រសួងអប់រំ យុវជន និងកីឡា ផ្លូវលេខ ៣៨០ សង្កាត់ បឹងកេងកង១ រាជធានីភ្នំពេញ',
        addressLine1: 'address line1',
        addressLine2: 'address line2',
        city: '',
        provinceState: '',
        state: '',
        countryCode: '',
      },
      defaultCurrency: 'usd',
      createdOn: '2022-02-03T10:43:47.467Z',
    },
  ],
}

// GET ALL DATA
mock.onGet('/app/clients/all').reply(200, {
  success: true,
  data,
})

// POST: Add new user
mock.onPost('/app/clients').reply(config => {
  // Get event from post data

  const client = JSON.parse(config.data)

  client.id = uuidv4()
  client.avatar = client.avatar || ''
  client.createdOn = new Date().toISOString()
  data.items.push(client)

  return [201, { client }]
})

// GET Updated DATA
mock.onGet('/app/clients').reply(config => {
  const { q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'fullName' } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.items.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    user => user.email.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      success: true,
      data: {
        total: filteredData.length,
        items: paginateArray(filteredData, perPage, page),
      },
    },
  ]
})

// GET USER
mock.onGet(new RegExp('/app/clients/*')).reply(config => {
  const id = config.url.split('/').at(-1)

  const client = data.items.find(i => i.id === id)
  return [200, { success: true, data: client }]
})

// DELETE: Deletes User
mock.onDelete(new RegExp('/app/clients/*')).reply(config => {
  // Get user id from URL
  const id = config.url.split('/').at(-1)
  const userIndex = data.items.findIndex(t => t.id === id)

  data.items.splice(userIndex, 1)

  return [200]
})

mock.onPut(new RegExp('/app/clients/*')).reply(config => {
  const id = config.url.split('/').at(-1)
  const userIndex = data.items.findIndex(t => t.id === id)
  const client = JSON.parse(config.data)
  data.items[userIndex] = client

  return [
    200,
    {
      success: true,
      data: client,
    },
  ]
})
