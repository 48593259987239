import axios from 'axios'
// import { authStore } from '@/store'
// import JsFileDownloader from 'js-file-downloader'

// import { AuthError } from '@/error'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  /**
   * timeout design
   *
   *  In situations during poor network connectivity requests can often be delayed
   *    and in these situations we do not want to "force" an error pre-maturely.
   *  Once a 20s threshold has been met however, the chance of real failure has dramatically
   *    increased so we'll force the failure so the user can retry incase of
   *    lost transmission / request.
   */
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
})

// add bearer token from store
axiosInstance.interceptors.request.use(
  async config => {
    // if (authStore.token.idToken) config.headers.Authorization = `Bearer ${authStore.token.idToken}`
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * Generic request handler for all JSON requests
 * @param {*} url
 * @param {*} method
 * @param {*} options
 * @returns {Array} [Axios promise, Axios Cancel]
 */
export const createRequest = (url, method = 'get', options = {}) => {
  const source = axios.CancelToken.source()
  const instance = axiosInstance
  const data = options.data
  delete options.data

  const axiosPromise = (
    ['post', 'put', 'patch'].includes(method)
      ? instance[method](url, data, {
          cancelToken: source.token,
          ...options,
        })
      : instance[method](url, {
          cancelToken: source.token,
          ...options,
        })
  )
    .catch(thrown => {
      if (axios.isCancel(thrown)) {
        console.log('Request cancelled', thrown.message)
      }
      return {
        data: {
          status: 'fail',
          data: {},
          error: thrown?.response?.data?.error ?? { _summary: 'Sorry an unexpected error has occurred' },
        },
        statusCode: thrown?.response?.status ?? 0, // 0 is network error
      }
    })
    // Normalise Axios response, excluding headers etc.
    .then(response => {
      // Request status code
      const statusCode = response?.statusCode ?? response?.status
      if (statusCode === 401) {
        // Refresh session
        // authStore.setExpireTick()
      }

      return {
        ...(response.data ?? {
          status: 'fail',
          data: {},
        }),
        statusCode,
      }
    })
  return [axiosPromise, source.cancel]
}

// /**
//  * File request handler for authenticated file downloads
//  * @param {String} url
//  * @param {String} filename
//  * @param {Object} options Pass optional event handlers
//  * @returns {Promise}
//  */
// export const createFileRequest = async (url, filename, { onProgress, onEnd, onError } = {}) => {
//   const token = await authStore.token.idToken
//   if (!token && !process.env.REACT_APP_DISABLE_AUTH) {
//     onError && onError(new AuthError())
//     return false
//   }
//   return new JsFileDownloader({
//     url,
//     filename,
//     headers: [{ name: 'Authorization', value: `Bearer ${token}` }],
//     process: function process(event) {
//       if (!event.lengthComputable) return // guard
//       const downloadingPercentage = Math.floor((event.loaded / event.total) * 100)
//       onProgress && onProgress(downloadingPercentage)
//     },
//   })
//     .then(e => onEnd && onEnd(e))
//     .catch(err => {
//       if (err?.request?.status === 401) {
//         authStore.setExpireTick() // Refresh session
//       }
//       onError && onError(err)
//     })
// }

const sendEmail = msg => {
  return axiosInstance.post('app/email/send', msg)
}

axiosInstance.email = {
  send: sendEmail,
}

export default axiosInstance

export const api = axiosInstance
