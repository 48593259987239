export const invoiceData = {
  items: [
    {
      id: 59030,
      issuedDate: '2022-02-07T11:38:16.006Z',
      dueDate: '2022-03-07T11:38:16.006Z',

      client: {
        displayName: 'Jordan Stevenson',
        company: 'Hall-Robbins PLC',
        primaryContactEmail: 'don85@johnson.com',
        primaryContactPhone: 'don85@johnson.com',
        address: {
          fullAddress: 'Hall-Robbins PLC',
        },
        mobiles: ['(616) 865-4180'],
        phones: ['(616) 865-4180'],
        emails: ['test_2@inkhmer.com', 'test_2@inkhmer.com'],
      },
      service: 'Software Development',

      avatar: '',
      invoiceStatus: 'Paid',

      items: [
        {
          id: 'eaeee8a0-9f1a-4422-b0ea-891b8b202801',
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 20000,
          discount: {
            value: 0,
            amount: 0,
            type: '%',
          },
        },
        {
          id: 'eaeee8a0-9f1a-b0ea-4422-891b8b202801',
          name: 'Translation text xx',
          qty: 10,
          rate: 100,
          total: 1000,
          discount: {
            value: 0,
            amount: 0,
            type: '%',
          },
        },
      ],
      currency: 'KHR',
      activity: {
        hasMore: true,
        items: [
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
        ],
      },
      attachment: {
        total: 100,
        size: 5,
        page: 1,
        items: [
          {
            id: 'f0477dec-b7de-418b-bf55-c98ea886e1ce',
            name: 'UltricesPosuereCubilia.pdf',
            createdOn: '2021-02-13T03:01:12Z',
          },
          {
            id: 'f014ead6-b6d0-4d2c-ae9f-b2a99c88291e',
            name: 'LuctusEtUltrices.ppt',
            createdOn: '2021-11-13T04:19:21Z',
          },
          {
            id: 'c5fe6c1a-70c4-4fdb-8d7c-6feb74c220e8',
            name: 'SagittisDuiVel.tiff',
            createdOn: '2021-06-05T02:26:42Z',
          },
          { id: 'd06e3544-d15b-49ed-9ef0-e986656b000c', name: 'EgestasMetus.mp3', createdOn: '2021-08-06T11:05:24Z' },
          {
            id: 'f6c22923-f3e8-418f-aa9f-668b55854d4d',
            name: 'LacusPurusAliquet.pdf',
            createdOn: '2022-02-02T05:47:28Z',
          },
        ],
      },
      discount: {},
      tax: {},
      balance: 1000,
      total: 3428,
    },
    {
      id: 59031,
      issuedDate: '2022-02-07T11:38:16.006Z',
      dueDate: '2023-03-07T11:38:16.006Z',

      client: {
        displayName: 'Stevenson Jordan',
        company: 'Hall-Robbins PLC',
        primaryContactEmail: 'don85@johnson.com',
        primaryContactPhone: 'don85@johnson.com',
        address: {
          fullAddress: 'Hall-Robbins PLC',
        },
        mobiles: ['(616) 865-4180'],
        phones: ['(616) 865-4180'],
        emails: ['test_2@inkhmer.com', 'test_2@inkhmer.com'],
      },
      service: 'Software Development',

      avatar: '',
      invoiceStatus: 'Paid',

      items: [
        {
          id: 'eaeee8a0-9f1a-4422-b0ea-891b8b202801',
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 20000,
          discount: {
            value: 0,
            amount: 0,
            type: '%',
          },
        },
        {
          id: 'eaeee8a0-9f1a-b0ea-4422-891b8b202801',
          name: 'Translation text xx',
          qty: 10,
          rate: 100,
          total: 1000,
          discount: {
            value: 0,
            amount: 0,
            type: '%',
          },
        },
      ],
      currency: 'KHR',
      activity: {
        hasMore: true,
        items: [
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
        ],
      },
      attachment: {
        total: 100,
        size: 5,
        page: 1,
        items: [
          {
            id: 'f0477dec-b7de-418b-bf55-c98ea886e1ce',
            name: 'UltricesPosuereCubilia.pdf',
            createdOn: '2021-02-13T03:01:12Z',
          },
          {
            id: 'f014ead6-b6d0-4d2c-ae9f-b2a99c88291e',
            name: 'LuctusEtUltrices.ppt',
            createdOn: '2021-11-13T04:19:21Z',
          },
          {
            id: 'c5fe6c1a-70c4-4fdb-8d7c-6feb74c220e8',
            name: 'SagittisDuiVel.tiff',
            createdOn: '2021-06-05T02:26:42Z',
          },
          { id: 'd06e3544-d15b-49ed-9ef0-e986656b000c', name: 'EgestasMetus.mp3', createdOn: '2021-08-06T11:05:24Z' },
          {
            id: 'f6c22923-f3e8-418f-aa9f-668b55854d4d',
            name: 'LacusPurusAliquet.pdf',
            createdOn: '2022-02-02T05:47:28Z',
          },
        ],
      },
      discount: {},
      tax: {},
      balance: 1000,
      total: 3428,
    },
    {
      id: 59032,
      issuedDate: '2022-02-07T11:38:16.006Z',
      dueDate: '2023-03-07T11:38:16.006Z',

      client: {
        displayName: 'Venson Jordan',
        company: 'Hall-Robbins PLC',
        primaryContactEmail: 'don85@johnson.com',
        primaryContactPhone: 'don85@johnson.com',
        address: {
          fullAddress: 'Hall-Robbins PLC',
        },
        mobiles: ['(616) 865-4180'],
        phones: ['(616) 865-4180'],
        emails: ['test_2@inkhmer.com', 'test_2@inkhmer.com'],
      },
      service: 'Software Development',

      avatar: '',
      invoiceStatus: 'Paid',

      items: [
        {
          id: 'eaeee8a0-9f1a-4422-b0ea-891b8b202801',
          name: 'Translation text',
          qty: 100,
          rate: 200,
          total: 20000,
          discount: {
            value: 0,
            amount: 0,
            type: '%',
          },
        },
        {
          id: 'eaeee8a0-9f1a-b0ea-4422-891b8b202801',
          name: 'Translation text xx',
          qty: 10,
          rate: 100,
          total: 1000,
          discount: {
            value: 0,
            amount: 0,
            type: '%',
          },
        },
      ],
      currency: 'KHR',
      activity: {
        hasMore: true,
        items: [
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
          {
            action: 'invoice.edit',
            createdOn: '2022-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            color: 'primary',
            properties: {},
          },
          {
            action: 'invoice.edit',
            color: 'secondary',
            createdOn: '2023-02-09T23:33:29.489Z',
            modifiedBy: 'Mony',
            properties: { invoiceNumber: 'KHR' },
          },
        ],
      },
      attachment: {
        total: 100,
        size: 5,
        page: 1,
        items: [
          {
            id: 'f0477dec-b7de-418b-bf55-c98ea886e1ce',
            name: 'UltricesPosuereCubilia.pdf',
            createdOn: '2021-02-13T03:01:12Z',
          },
          {
            id: 'f014ead6-b6d0-4d2c-ae9f-b2a99c88291e',
            name: 'LuctusEtUltrices.ppt',
            createdOn: '2021-11-13T04:19:21Z',
          },
          {
            id: 'c5fe6c1a-70c4-4fdb-8d7c-6feb74c220e8',
            name: 'SagittisDuiVel.tiff',
            createdOn: '2021-06-05T02:26:42Z',
          },
          { id: 'd06e3544-d15b-49ed-9ef0-e986656b000c', name: 'EgestasMetus.mp3', createdOn: '2021-08-06T11:05:24Z' },
          {
            id: 'f6c22923-f3e8-418f-aa9f-668b55854d4d',
            name: 'LacusPurusAliquet.pdf',
            createdOn: '2022-02-02T05:47:28Z',
          },
        ],
      },
      discount: {},
      tax: {},
      balance: 1000,
      total: 3428,
    },
  ].map(x => {
    x.number = x.id
    return x
  }),
}
