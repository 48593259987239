import mock from '@src/@fake-db/mock'
import { paginateArray } from '@src/@fake-db/utils'

export const activityData = {
  hasMore: true,
  total: 100,
  size: 5,
  page: 1,
  items: [
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
    {
      action: 'invoice.edit',
      createdOn: '2022-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      color: 'primary',
      properties: {},
    },
    {
      action: 'invoice.edit',
      color: 'secondary',
      createdOn: '2023-02-09T23:33:29.489Z',
      modifiedBy: 'Mony',
      properties: { invoiceNumber: 'KHR' },
    },
  ],
}

mock.onGet(new RegExp('/app/audit-logs/invoice/*')).reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = activityData.items.sort((a, b) => {
    if (!sortColumn) {
      return 1
    }
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter(acitvity => {
    return true
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    return acitvity.description?.toLowerCase().includes(queryLowered)
  })
  /* eslint-enable  */

  return [
    200,
    {
      success: true,
      data: {
        total: filteredData.length,
        items: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page),
      },
    },
  ]
})
