// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { enUS, km } from 'date-fns/locale'
import { createRequest } from '../api'
import set from 'lodash/set'
export const getConfigs = createAsyncThunk('config/getConfigs', async () => {
  const data = await createRequest('app/configuration/global')[0]

  return {
    data,
  }
})

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    languages: [],
    lang: 'en',
    currencies: [],
    defaults: {
      language: 'en',
      country: 'KH',
      currency: 'USD',
      timezone: 'Asia/Bangkok',
      dateFormat: '',
      dateTimeFormat: '',
    },
    invoice: {
      defaultNote: '',
      unitTypes: [],
    },
    office: {
      phones: [],
      address: {
        fullAddress: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        provinceState: '',
        zipPostCode: '',
        countryCode: '',
      },
    },
    payment: {
      acceptedMethods: [],
    },
    general: {},
  },
  reducers: {
    updateUnitTypes: (state, action) => {
      state.invoice.unitTypes = action.payload
    },
    updateKey: (state, action) => {
      set(state, action.payload.key, action.payload.value)
    },
  },
  extraReducers: builder => {
    builder.addCase(getConfigs.fulfilled, (state, action) => {
      state.languages = action.payload.data.languages
      state.lang = action.payload.data.setting.localization.defaultLanguage
      state.currencies = action.payload.data.currencies
      state.company = action.payload.data.company
      state.invoice = action.payload.data.invoice
      state.payment = action.payload.data.payment
      state.defaults = {
        ...action.payload.data.defaults,
        language: action.payload.data.setting.localization.defaultLanguage,
      }
      state.invoice.defaultNote = action.payload.data.invoice.defaultNote
      state.general = action.payload.data.general
    })
  },
})

export const { updateUnitTypes, updateKey } = configSlice.actions

export default configSlice.reducer
