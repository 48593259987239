// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import api from '@api'
import { updateKey } from '../../../redux/configuration'

export const getData = createAsyncThunk('setting/getData', async params => {
  const response = await api.get('app/setting/account', params)
  return response.data.data
})

export const saveData = createAsyncThunk('setting/saveData', async function (params, { dispatch }) {
  const response = await api.put('app/setting/account', params)
  console.log(params)

  if (params.general?.unitTypes) {
    dispatch({
      type: 'config/updateUnitTypes',
      payload: params.general.unitTypes,
    })
  }

  dispatch(updateKey({ key: 'general', value: params.general }))

  if (params.general?.taxes) {
    dispatch(updateKey({ key: 'taxes', value: params.general.taxes }))
  }

  return response.data.data
})

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    loaded: false,
    general: {
      languageCode: 'kh',
      currencyCode: 'USD',
      timezoneCode: 'Asia/Bangkok',
      dateFormat: '',
      unitTypes: [],
    },
    company: {
      lastName: '',
      firstName: '',
      email: '',
      phoneNumber: '',
      address: {
        fullAddress: '',
      },
      provinceState: '',
      zipPostCode: '',
      countryCode: '',
      industryId: '',
      avatar: '',
    },
    social: {
      twitter: '',
      facebook: '',
      google: '',
      linkedIn: '',
      instagram: '',
      quora: '',
    },
    notification: {
      accountActivity: [],
      newBrowserSignIn: [],
      newDeviceIsLinked: [],
      newForYou: [],
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.pending, state => {
      state.loaded = false
    })
    builder.addCase(getData.fulfilled, (state, action) => {
      state.general = action.payload.general
      state.company = action.payload.company
      state.notification = action.payload.notification
      state.social = action.payload.social
      state.loaded = true
    })
    builder.addCase(saveData.pending, state => {
      state.loaded = false
    })
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.general = action.payload.general
      state.company = action.payload.company
      state.notification = action.payload.notification
      state.social = action.payload.social

      state.loaded = true
    })
  },
})

export default settingSlice.reducer
