export default {
  languageCode: 'kh',
  currencyCode: 'USD',
  timezoneCode: 'Asia/Bangkok',
  dateFormat: 'dd/MM/yyyy',
  timeFormat: 'pp',
  unitTypes: [
    {
      id: '43bee778-24cc-4bc4-b0ab-d6ad17b16c9b',
      label: 'Days',
    },
    {
      id: 'f243c08b-7840-4f38-ba06-d46ef3fcf026',
      label: 'Hours',
    },
    {
      id: 'c93acd43-f62c-4a89-bd27-4ba7ec873bce',
      label: 'Pages',
    },
    {
      id: '4361c5c2-9623-4ebc-8785-3ab8216ee562',
      label: 'Slides',
    },
    {
      id: '6a83cb5f-158c-43b2-bce7-56071b28c452',
      label: 'Units',
    },
  ],
  taxes: [
    {
      id: 'vat',
      name: 'VAT',
      rate: 0.1,
      enabled: true,
    },
    {
      id: 'gst',
      name: 'GST',
      rate: 0.2,
      enabled: true,
    },
  ],
  clientNote:
    'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
}
