import * as yup from 'yup'

export const yupMessages = {
  nameFormat: 'Please only enter letters, numbers, apostrophes, spaces, and hyphens in this field (i.e. 0-9, a-z, ‘ -)',
  lettersOnly: 'Please only enter letters, apostrophes, spaces, and hyphens in this field (i.e. a-z, ‘ -)',
  alphanumericOnly: 'Please only enter letters or numbers in this field (i.e. 0-9, a-z)',
  numbersOnly: 'Please only enter numbers in this field (i.e. 0-9)',
  required: 'Please enter a valid value for this field.',
  requiredIntlNumber: 'Please enter a valid international mobile number.',
  phoneNumber: 'Please only enter numbers, the plus sign, brackets and dashes in this field (i.e. 0-9, +, (), -)',
  intlPhoneNumber:
    'Please enter a valid international mobile number, including your country code (e.g. +61 420 000 000)',
  lettersNumbersOnly:
    'Please only enter letters, numbers, apostrophes, spaces, and hyphens in this field (i.e. a-z, ‘ -)',
  email:
    'Please only enter letters, numbers, apostrophes, spaces, underscore, at (@),  and hyphens in this field (i.e. 0-9, a-z, ‘ - _)',
}

yup.setLocale({
  string: {
    max: ({ max }) => `Please only enter up to ${max} characters in this field.`,
  },
})

export const yupStringMaxReq = (max, addOn, isRequired = true) => {
  const requireType = isRequired ? 'required' : 'optional'
  switch (addOn) {
    case 'nameFormat':
      return yup
        .string()
        .max(max)
        .matches(/^[0-9A-Za-z\s'-p{L}]*$/, yupMessages.nameFormat)
        [requireType](yupMessages.required)
    case 'lettersOnly':
      return yup
        .string()
        .max(max)
        .matches(/^[A-Za-z\s'-]*$/, yupMessages.lettersOnly)
        [requireType](yupMessages.required)
    case 'alphanumericOnly':
      return yup
        .string()
        .max(max)
        .matches(/^[\w\s]*$/, yupMessages.alphanumericOnly)
        [requireType](yupMessages.required)
    case 'numbersOnly':
      return yup
        .string()
        .max(max)
        .matches(/^[\d]*$/, yupMessages.numbersOnly)
        [requireType](yupMessages.required)
    case 'phoneNumber':
      return yup
        .string()
        .max(max)
        .matches(/^[\d+\s)(-]*$/, yupMessages.phoneNumber)
        [requireType](yupMessages.required)
    case 'intlPhoneNumber':
      return yup
        .string()
        .max(20)
        .matches(/^\+[0-9\s]{10,}$/, yupMessages.intlPhoneNumber)
        [requireType](yupMessages.requiredIntlNumber)
    case 'lettersNumbersOnly':
      return yup
        .string()
        .max(max)
        .matches(/^[\w\d\s'-]*$/, yupMessages.lettersNumbersOnly)
        [requireType](yupMessages.required)
    case 'email':
      return yup
        .string()
        .max(50)
        .matches(/^[\w\d\s'-_@]*$/, yupMessages.email)
        .email('Must be a valid email address')
        [requireType](yupMessages.required)
    case 'stripHtml':
      return yup
        .string()
        .test('html-length', `Please only enter up to ${max} characters in this field.`, value => {
          if (!value) return true
          const strippedHtml = value.replace(/(<([^>]+)>)/gi, '')
          return strippedHtml.length <= max
        })
        [requireType](yupMessages.required)
    default:
      return yup.string().max(max).required(yupMessages.required)
  }
}

export const yupIfTrue = (i, func) => {
  return yup.string().when(i, {
    is: true,
    then: func,
  })
}
